import React, { useState, useEffect } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Row, Space, Spin, Table, notification, DatePicker, Checkbox, Modal, Form } from 'antd'
import axios from 'axios';
import dayjs from "dayjs";
import { currencyFormatOne, msData, msPays, msError, msOK, msCancel, msErrorInsert2, msDataContnoZum, msDataPro } from "../file_mid/allFormat";
import DetailContno from "./DetailContno";
import { loadDataAuto, insertPayamt } from "../file_mid/all_api";
// import { onMessageListener } from "../../firebase";
import { columnsMiss } from "./columns";

export default function Main() {
  const { confirm } = Modal
  const [form] = Form.useForm();
  const user = localStorage.getItem('username');
  const branch = localStorage.getItem('branch');
  // const currentToken = localStorage.getItem("currentToken");
  const token = localStorage.getItem('token');
  const minDate = dayjs('2025-01-12');  // วันที่ขั้นต่ำ
  const currentDateTime = dayjs();
  // const oneDayAgo = currentDateTime.subtract(1, 'day');
  const [dataStatus, setDataStatus] = useState(200);
  const [dataPost, setDataPost] = useState({ sec: 0, days1: currentDateTime.format('YYYY-MM-DD'), days2: currentDateTime.format('YYYY-MM-DD') });
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  // const [arrayTableMysql, setArrayTableMysql] = useState([]);
  const [arrayTableMiss, setArrayTableMiss] = useState([]);
  const [arrayTableNopay, setArrayTableNopay] = useState([]);
  const [arrayTableNopayTON, setArrayTableNopayTON] = useState([]);
  const [arrayTableTNOTEFL, setArrayTableTNOTEFL] = useState([]);
  const [resMix, setResMix] = useState();
  const [dataDetail, setDataDetail] = useState({ arr: [], money: 0, moneyNopay: 0, moneyNopayTON: 0, countCont: 0, sumMoneys: 0, sumAmountTNOTEFL: 0, sumMoneysAMT: 0, sumMoneysHD: 0, AMT: [], HD: [] });
  const [checkMoney, setCheckMoney] = useState(0);
  const [checkMoneyHD, setCheckMoneyHD] = useState(0);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false)
  const [isModalInfo, setIsModalInfo] = useState(false);
  const [checkInsert, setCheckInsert] = useState(false);
  const [connt, setConnt] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // สัญญาที่รับเข้างวดได้
  const [selectedRowsHD, setSelectedRowsHD] = useState([]); // เข้ารับฝาก
  const [query, setQuery] = useState("");
  const [isClicked, setIsClicked] = useState(1);
  // useEffect(() => {
  //   loadData();
  // }, [])

  const loadData = async (load) => {
    let mainData = {}
    var resultLoadDataAuto = []
    var resultLoadDataAutoMiss = []
    var resultLoadDataAutoNopay = []
    var resultLoadDataAutoNopayTON = []
    var resultLoadDataTNOTEFL = []
    var resultDetail = []
    var resultMysql = []
    let sumAmount = 0;
    let sumAmountNopay = 0;
    let sumAmountNopayTON = 0;
    let sumAmountTNOTEFL = 0;
    let countCont = 0;
    let sumMoneys = 0;
    let sumMoneysAMT = 0;
    let sumMoneysHD = 0;
    var resultAMT = []
    var resultHD = []
    if (load === "load") {
      setLoading(true)
    }
    setIsClicked(1);
    setCheckMoney(0);
    setCheckMoneyHD(0);
    // const tk = JSON.parse(token)
    // const headers = {
    //   "Authorization": `Bearer ${tk}`,
    //   "Menu": JSON.stringify("4")
    // }
    mainData = { day1: dataPost.days1, day2: dataPost.days2, user: user, branch: branch }
    console.log("mainData", mainData)
    // await axios.post("http://localhost:8070/api-db2/load-data-auto", mainData)
    await axios.post(loadDataAuto, mainData)
      .then(async (res) => {
        console.log("loadData 1 =>", res.data)
        if (res.status === 200) {
          setDataStatus(200)
          setCheckInsert(false);
          setResMix(res.data)
          if (res.data.VIEWARMASTBC) {
            await res.data.VIEWARMASTBC.forEach((item) => {
              if (item.CONTNO !== "") {
                if (item.flagTNOTEFL === true) {
                  resultLoadDataTNOTEFL.push(item)
                  sumAmountTNOTEFL = sumAmountTNOTEFL + item.money
                } else {
                  if (item.flagTONBALANCE === true) {
                    resultLoadDataAuto.push(item)
                    if (item.idMysql > 0) {
                      countCont++
                      sumMoneys = sumMoneys + item.amountMysql
                      resultMysql.push(item)
                      if (item.HDMysql > 0) {
                        sumMoneysHD = sumMoneysHD + item.amountMysql
                        resultHD.push(item)
                      } else {
                        sumMoneysAMT = sumMoneysAMT + item.amountMysql
                        resultAMT.push(item)
                      }
                    }
                    // if (item.flagFollow === true && item.money >= item.PAYLIMITAMT) {
                    if (item.flagFollow === true) {
                      if (item.GRDCOD === "A" || item.GRDCOD === "B" || item.GRDCOD === "C") {
                        if (item.money >= (item.PAYLIMITAMT - 100)) {
                          resultDetail.push(item)
                          sumAmount = sumAmount + item.money
                        } else {
                          resultLoadDataAutoNopay.push(item)
                          sumAmountNopay = sumAmountNopay + item.money
                        }
                      } else {
                        if (item.money >= item.PAYLIMITAMT) {
                          resultDetail.push(item)
                          sumAmount = sumAmount + item.money
                        } else {
                          resultLoadDataAutoNopay.push(item)
                          sumAmountNopay = sumAmountNopay + item.money
                        }
                      }
                    } else if (item.flagFollow === false) {
                      resultDetail.push(item)
                      sumAmount = sumAmount + item.money
                    } else {
                      // console.log("item", item)
                      resultLoadDataAutoNopay.push(item)
                      sumAmountNopay = sumAmountNopay + item.money
                    }
                  } else {
                    // resultLoadDataAutoNopay.push(item)
                    // sumAmountNopay = sumAmountNopay + item.money
                    resultLoadDataAutoNopayTON.push(item)
                    sumAmountNopayTON = sumAmountNopayTON + item.money
                  }
                }
              } else {
                resultLoadDataAutoMiss.push(item)
              }
            })
            setArrayTable(resultLoadDataAuto) // เจอ
            setAxiosData(resultLoadDataAuto) // สำรอง
            setArrayTableMiss(resultLoadDataAutoMiss) // หาไม่เจอ
            setArrayTableNopay(resultLoadDataAutoNopay) // รับไม่ได้ เข้ารับฝาก
            setArrayTableNopayTON(resultLoadDataAutoNopayTON) // รับไม่ได้ เกินต้นคงเหลือ
            // setArrayTableMysql(resultMysql) // รับเข้ามีบิลแล้ว
            setArrayTableTNOTEFL(resultLoadDataTNOTEFL) // ส่งชุดนอก
            if (resultDetail?.length > 0) {
              setDataDetail({
                arr: resultDetail, money: sumAmount, moneyNopay: sumAmountNopay, moneyNopayTON: sumAmountNopayTON,
                countCont: countCont, sumMoneys: sumMoneys, sumAmountTNOTEFL: sumAmountTNOTEFL,
                sumMoneysAMT: sumMoneysAMT, sumMoneysHD: sumMoneysHD, AMT: resultAMT, HD: resultHD
              })
            }
          }
        } else if (res.status === 204) {
          funcSet("0")
          msDataContnoZum(api, "top")
        } else if (res.status === 205) {
          // setTimeout(() => {
          msDataPro(api, "top")
          funcSet("205")
          // }, 5000);
        } else {
          funcSet("0")
          msData(api, "top")
        }
        setSelectedRows([])
        setSelectedRowsHD([])
        setQuery("")
        setLoading(false)
      }).catch((err) => {
        funcSet("0")
        setSelectedRows([])
        setSelectedRowsHD([])
        setQuery("")
      })
    setLoading(false)
  }
  const funcSet = (value) => {
    if (value === "205") {
      setDataStatus(205)
    } else {
      setDataStatus(200)
    }
    setCheckInsert(false);
    setResMix()
    setDataDetail({ arr: [], money: 0, moneyNopay: 0, moneyNopayTON: 0, countCont: 0, sumMoneys: 0, sumAmountTNOTEFL: 0, sumMoneysAMT: 0, sumMoneysHD: 0, AMT: [], HD: [] })
    // setArrayTableMysql([])
    setArrayTable([])
    setAxiosData([])
    setArrayTableMiss([])
    setArrayTableNopay([])
    setArrayTableNopayTON([])
    setArrayTableTNOTEFL([])
  }
  // console.log("dataDetail", dataDetail)
  const handleSubmit = () => {
    loadData("load")
  }
  const showConfirm = () => {
    confirm({
      title: 'คอมเฟิร์มทำรายการ...?',
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        setLoading(true)
        handleCheck()
      },
      onCancel() {
        msCancel(api, "top")
      },
    });
  }
  // const testNoti = async () => {
  //   setLoading(true)
  //   await axios.post('http://localhost:8070/api-db2/TestSendFCMNotification', { fcmToken: currentToken })
  //     .then(async (res) => {
  //       console.log("Notification sent successfully:", res);
  //       setLoading(false)
  //     }).catch((err) => console.log(err))
  //   setLoading(false)
  // }
  const handleCheck = async () => {
    let mix = {}
    console.log("selectedRows", selectedRows)
    console.log("selectedRowsHD", selectedRowsHD)
    if (checkInsert === false) {
      if (selectedRows?.length > 0 || selectedRowsHD?.length > 0) {
        const tk = JSON.parse(token)
        const headers = {
          "Authorization": `Bearer ${tk}`,
          "Menu": JSON.stringify("26")
        }
        mix = { normal: selectedRows, hd: selectedRowsHD }
        await axios.post(insertPayamt, mix, { headers: headers })
          .then(async (res) => {
            if (res.status === 200) {
              console.log("ok", res.data)
              // setTimeout(() => {
              setCheckInsert(true)
              loadData("noload")
              // }, 1500);
              msOK(api, "top")
            } else {
              setLoading(false)
            }
          })
          .catch((err) => {
            console.log("err", err)
            msError(api, "top")
            setLoading(false)
          })
      } else {
        msPays(api, "top")
        setLoading(false)
      }
    } else {
      msErrorInsert2(api, "top")
      setLoading(false)
    }
  }

  const search = (data) => {
    // onSelectAllChange({ target: { checked: false } });  // ส่งค่า false เพื่อรีเซ็ต Checkbox เลือกทั้งหมด
    setSelectedRows([]); // Deselect all
    setSelectedRowsHD([]);
    setCheckMoney(0);
    setCheckMoneyHD(0);
    setQuery(data.target.value);
    const greaterThanTen = axiosData.filter(
      (item) =>
        item.CONTNO.toLowerCase().includes(data.target.value)
    );
    setArrayTable(greaterThanTen);
  };
  // Handle selecting all rows
  const onSelectAllChange = (e) => {
    console.log("e.target.checked", e.target.checked)
    let sum = 0;
    let sumHD = 0;
    if (isClicked === 1) {
      if (e.target.checked) {
        setSelectedRows(arrayTable.filter(row => {
          if (row.idMysql > 0) {
            return
          } else {
            if (row.flagTONBALANCE === true && row.flagTNOTEFL === false) {
              if (row.GRDCOD === "A" || row.GRDCOD === "B" || row.GRDCOD === "C") {
                return (row.flagFollow === true && row.money >= (row.PAYLIMITAMT - 100)) || row.flagFollow === false;
              } else {
                return (row.flagFollow === true && row.money >= row.PAYLIMITAMT) || row.flagFollow === false;
              }
            } else {
              return
            }
          }
        }));
        setSelectedRowsHD(arrayTable.filter(row => {
          if (row.idMysql > 0) {
            return
          } else {
            if (row.flagTONBALANCE === true && row.flagTNOTEFL === false) {
              if (row.GRDCOD === "A" || row.GRDCOD === "B" || row.GRDCOD === "C") {
                return (row.flagFollow === true && row.money < (row.PAYLIMITAMT - 100));
              } else {
                return (row.flagFollow === true && row.money < row.PAYLIMITAMT);
              }
            } else {
              return
            }
          }
        }));
        arrayTable.forEach((item) => {
          if (item.idMysql > 0) {
            // มีการนำเข้าแล้ว
          } else {
            if (item.flagTNOTEFL === false) {
              if (item.CONTNO !== "" && item.flagTONBALANCE === true) {
                if (item.flagFollow === true) {
                  if (item.GRDCOD === "A" || item.GRDCOD === "B" || item.GRDCOD === "C") {
                    if (item.money >= (item.PAYLIMITAMT - 100)) {
                      sum = sum + item.money
                    }
                    if (item.money < (item.PAYLIMITAMT - 100)) {
                      sumHD = sumHD + item.money
                    }
                  } else {
                    if (item.money >= item.PAYLIMITAMT) {
                      sum = sum + item.money
                    }
                    if (item.money < item.PAYLIMITAMT) {
                      sumHD = sumHD + item.money
                    }
                  }
                } else if (item.CONTNO !== "" && item.flagFollow === false) {
                  sum = sum + item.money
                } else {
                  // ไม่เข้าเลย
                }
              }
            }
          }
        })
        setCheckMoney(sum);
        setCheckMoneyHD(sumHD);
      } else {
        setSelectedRows([]); // Deselect all
        setSelectedRowsHD([]);
        setCheckMoney(0);
        setCheckMoneyHD(0);
      }
    } else if (isClicked === 3) {
      if (e.target.checked) {
        setSelectedRowsHD(arrayTable.filter(row => {
          if (row.idMysql > 0) {
            return
          } else {
            if (row.flagTONBALANCE === true && row.flagTNOTEFL === false) {
              if (row.GRDCOD === "A" || row.GRDCOD === "B" || row.GRDCOD === "C") {
                return (row.flagFollow === true && row.money < (row.PAYLIMITAMT - 100));
              } else {
                return (row.flagFollow === true && row.money < row.PAYLIMITAMT);
              }
            } else {
              return
            }
          }
        }));
        arrayTable.forEach((item) => {
          if (item.idMysql > 0) {
            // มีการนำเข้าแล้ว
          } else {
            if (item.flagTNOTEFL === false) {
              if (item.CONTNO !== "" && item.flagTONBALANCE === true) {
                if (item.flagFollow === true) {
                  if (item.GRDCOD === "A" || item.GRDCOD === "B" || item.GRDCOD === "C") {
                    if (item.money < (item.PAYLIMITAMT - 100)) {
                      sumHD = sumHD + item.money
                    }
                  } else {
                    if (item.money < item.PAYLIMITAMT) {
                      sumHD = sumHD + item.money
                    }
                  }
                } else {
                  // ไม่เข้าเลย
                }
              }
            }
          }
        })
        setCheckMoneyHD(sumHD)
      } else {
        setSelectedRows([]); // Deselect all
        setSelectedRowsHD([]);
        setCheckMoney(0);
        setCheckMoneyHD(0);
      }
    } else {
      console.log("isClicked ==> ", isClicked)
      setSelectedRows([]); // Deselect all
      setSelectedRowsHD([]);
      setCheckMoney(0);
      setCheckMoneyHD(0);
    }
  };
  // Handle selecting individual row
  const onSelectChange = (record, flag, status) => {
    var results = {}
    let sum = 0;
    let sumHD = 0;
    console.log("flag  =>", flag)
    console.log("status  =>", status)
    if (isClicked === 1) {
      if (status === "ปกติ") {
        if (selectedRows.find(row => row.keyId === record.keyId)) { // คลิกเอาออก
          // console.log("selectedRows =>", selectedRows.filter(row => row.keyId !== record.keyId))
          results = selectedRows.filter(row => row.keyId !== record.keyId)
          setSelectedRows(results);
          results.forEach((item) => { sum = sum + item.money })
        } else { // คลิกเพิ่ม
          console.log("record =>", record)
          if (selectedRows?.length > 0) {
            // console.log("1", selectedRows)
            selectedRows.forEach((item) => { sum = sum + item.money })
            // console.log("sum", sum)
          }
          setSelectedRows([...selectedRows, record]); // Add full record instead of just uid
          sum = sum + record.money
        }
        setCheckMoney(sum)
      } else if (status === "รับฝาก") {
        if (selectedRowsHD.find(row => row.keyId === record.keyId)) { // คลิกเอาออก
          // console.log("selectedRows =>", selectedRows.filter(row => row.keyId !== record.keyId))
          results = selectedRowsHD.filter(row => row.keyId !== record.keyId)
          setSelectedRowsHD(results);
          results.forEach((item) => { sumHD = sumHD + item.money })
        } else { // คลิกเพิ่ม
          console.log("record =>", record)
          if (selectedRowsHD?.length > 0) {
            // console.log("1", selectedRows)
            selectedRowsHD.forEach((item) => { sumHD = sumHD + item.money })
            // console.log("sumHD", sumHD)
          }
          setSelectedRowsHD([...selectedRowsHD, record]); // Add full record instead of just uid
          sumHD = sumHD + record.money
        }
        setCheckMoneyHD(sumHD)
      } else {
        console.log("error", record, flag, status)
      }
    } else if (isClicked === 3) {
      if (selectedRowsHD.find(row => row.keyId === record.keyId)) { // คลิกเอาออก
        // console.log("selectedRows =>", selectedRows.filter(row => row.keyId !== record.keyId))
        results = selectedRowsHD.filter(row => row.keyId !== record.keyId)
        setSelectedRowsHD(results);
        results.forEach((item) => { sumHD = sumHD + item.money })
      } else { // คลิกเพิ่ม
        console.log("record =>", record)
        if (selectedRowsHD?.length > 0) {
          // console.log("1", selectedRows)
          selectedRowsHD.forEach((item) => { sumHD = sumHD + item.money })
          // console.log("sumHD", sumHD)
        }
        setSelectedRowsHD([...selectedRowsHD, record]); // Add full record instead of just uid
        sumHD = sumHD + record.money
      }
      setCheckMoneyHD(sumHD)
    } else {
      console.log("isClicked ===> ", isClicked)
    }
  };
  const buttonAmt = async (value) => {
    setIsClicked(value)
    setSelectedRows([]); // Deselect all
    setSelectedRowsHD([]);
    setCheckMoney(0);
    setCheckMoneyHD(0);
    setQuery("");
  }
  const columns = [
    {
      title: (
        <>
          {
            isClicked === 1 ?
              <Checkbox
                style={{ transform: 'scale(1.5)' }}
                onChange={onSelectAllChange}
                // checked={selectedRows.length === arrayTable.length && arrayTable.length > 0}
                indeterminate={selectedRows.length > 0 && selectedRows.length < arrayTable.length}
              />
              :
              isClicked === 3 ?
                <Checkbox
                  style={{ transform: 'scale(1.5)' }}
                  onChange={onSelectAllChange}
                  indeterminate={selectedRowsHD.length > 0 && selectedRowsHD.length < arrayTable.length}
                />
                :
                null
          }
        </>
      ),
      dataIndex: 'select',
      key: 'select',
      align: 'center',
      width: "10%",
      render: (text, record) => (
        <>
          {
            record.idMysql > 0 ?
              <> <b style={{ fontSize: '14px', color: 'red' }}>** รับเข้าแล้ว **</b></>
              :
              <>
                {
                  isClicked === 1 ?
                    <>
                      {
                        record.flagFollow === true && record.flagTONBALANCE === true && record.flagTNOTEFL === false ?
                          <>
                            {
                              record.GRDCOD === "A" || record.GRDCOD === "B" || record.GRDCOD === "C" ?
                                <>
                                  {
                                    record.money >= (record.PAYLIMITAMT - 100) ?
                                      <Checkbox
                                        style={{ transform: 'scale(1.5)' }}
                                        onChange={() => onSelectChange(record, true, "ปกติ")}
                                        checked={selectedRows.find(row => row.keyId === record.keyId)}
                                      />
                                      :
                                      <Checkbox
                                        style={{ transform: 'scale(1.5)' }}
                                        onChange={() => onSelectChange(record, true, "รับฝาก")}
                                        checked={selectedRowsHD.find(row => row.keyId === record.keyId)}
                                      />
                                  }
                                </>
                                :
                                <>
                                  {
                                    record.money >= record.PAYLIMITAMT ?
                                      <Checkbox
                                        style={{ transform: 'scale(1.5)' }}
                                        onChange={() => onSelectChange(record, true, "ปกติ")}
                                        checked={selectedRows.find(row => row.keyId === record.keyId)}
                                      />
                                      :
                                      <Checkbox
                                        style={{ transform: 'scale(1.5)' }}
                                        onChange={() => onSelectChange(record, true, "รับฝาก")}
                                        checked={selectedRowsHD.find(row => row.keyId === record.keyId)}
                                      />
                                  }
                                </>
                            }
                          </>
                          :
                          record.flagFollow === false && record.flagTONBALANCE === true && record.flagTNOTEFL === false ?
                            // record.flagFollow === false && record.money >= record.TOTUPAY ?
                            <Checkbox
                              style={{ transform: 'scale(1.5)' }}
                              onChange={() => onSelectChange(record, false, "ปกติ")}
                              checked={selectedRows.find(row => row.keyId === record.keyId)}
                            />
                            : null
                      }
                    </>
                    :
                    isClicked === 3 ?
                      <>
                        {
                          record.flagFollow === true && record.flagTONBALANCE === true && record.flagTNOTEFL === false ?
                            <>
                              {
                                record.GRDCOD === "A" || record.GRDCOD === "B" || record.GRDCOD === "C" ?
                                  <>
                                    {
                                      record.money < (record.PAYLIMITAMT - 100) ?
                                        <Checkbox
                                          style={{ transform: 'scale(1.5)' }}
                                          onChange={() => onSelectChange(record, true, "รับฝาก")}
                                          checked={selectedRowsHD.find(row => row.keyId === record.keyId)}
                                        />
                                        : null
                                    }
                                  </>
                                  :
                                  <>
                                    {
                                      record.money < record.PAYLIMITAMT ?
                                        <Checkbox
                                          style={{ transform: 'scale(1.5)' }}
                                          onChange={() => onSelectChange(record, true, "รับฝาก")}
                                          checked={selectedRowsHD.find(row => row.keyId === record.keyId)}
                                        />
                                        : null
                                    }
                                  </>
                              }
                            </>
                            :
                            record.flagFollow === false && record.flagTONBALANCE === true && record.flagTNOTEFL === false ?
                              <Checkbox
                                style={{ transform: 'scale(1.5)' }}
                                onChange={() => onSelectChange(record, false, "ปกติ")}
                                checked={selectedRowsHD.find(row => row.keyId === record.keyId)}
                              />
                              : null
                        }
                      </>
                      : null
                }
              </>
          }
        </>
      ),
    },
    {
      key: 'index',
      title: "ลำดับ",
      dataIndex: "index",
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      key: 'index',
      title: "BANK_CODE",
      dataIndex: "index",
      width: "auto",
      align: 'center',
      render: (text, record) => (
        <>
          <div>ชำระด้วย : <b style={{ fontSize: '14px', color: 'red' }}>{record.BANK_CODE_00}</b></div>
        </>
      ),
    },
    {
      title: "รายละเอียดสัญญา",
      dataIndex: "CONTNO",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <>
          <div>เลขสัญญา : {record.CONTNO}</div>
          <div>เกรด : {record.GRDCOD}</div>
          <div>ค่างวด : <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(record.TOTUPAY)}</b></div>
          {
            record.flagFollow === true ?
              <div>ชำระขั้นต่ำ : <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(record.PAYLIMITAMT)}</b></div>
              : null
          }
        </>
      ),
    },
    {
      key: 'money',
      title: "รายละเอียดการโอน",
      dataIndex: "money",
      width: "auto",
      align: 'center',
      render: (text, record) => (
        <>
          <div>วันที่ชำระ : {dayjs(record.PAYMENTDATE).format("DD-MM-YYYY")}</div>
          <div>ยอดเงิน : <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(record.moneyCut)}</b></div>
          {
            record.moneyFollow > 0 ?
              <div>ค่าติดตาม : <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(record.moneyFollow)}</b></div>
              : <div>ค่าติดตาม : <b style={{ fontSize: '14px', color: 'red' }}>0.00</b></div>
          }
          <div>ยอดที่เหลือ : <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(record.money)}</b></div>
        </>
      ),
    },
    {
      title: "รายละเอียดการรับเข้า",
      dataIndex: "idMysql",
      key: 'index',
      align: 'center',
      width: "15%",
      render: (text, record) => (
        <>
          {
            record.idMysql > 0 ?
              <>
                <div>วันที่ชำระ : {dayjs(record.paymentDateMysql).format("DD-MM-YYYY")}</div>
                <div>เลขที่ใบเสร็จ : <b style={{ fontSize: '14px', color: 'red' }}>{record.tmBill}</b></div>
                <div>รับสุทธิ : <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(record.amountMysql)}</b></div>
                {
                  record.HDMysql > 0 ?
                    <div>เข้ารับฝาก : <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(record.HDMysql)}</b></div>
                    :
                    <>
                      <div>ชำระค่างวด : <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(record.payamtMysql)}</b></div>
                      <div>ชำระค่าทวงถาม : <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(record.followMysql)}</b></div>
                    </>
                }
              </>
              :
              <>
                {
                  record.flagTNOTEFL === true ?
                    <>
                      <div><b style={{ fontSize: '14px', color: 'red' }}>** ส่งชุดนอก **</b></div>
                      <div><b style={{ fontSize: '14px', color: 'red' }}>** {record.FOLLOWCOD_TNOTEFL} ({record.NIKNAME_TNOTEFL}) **</b></div>
                    </>
                    :
                    <>
                      {
                        record.flagTONBALANCE === true ?
                          <>
                            {
                              record.flagFollow === true ?
                                <>
                                  {
                                    record.GRDCOD === "A" || record.GRDCOD === "B" || record.GRDCOD === "C" ?
                                      <>
                                        {
                                          record.money >= (record.PAYLIMITAMT - 100) ?
                                            <>
                                              <b style={{ fontSize: '14px', color: 'red' }}>** ยังไม่ได้รับเข้า **</b>
                                            </>
                                            :
                                            <>
                                              <div><b style={{ fontSize: '14px', color: 'red' }}>** ไม่ถึงขั้นต่ำ **</b></div>
                                              <div><b style={{ fontSize: '14px', color: 'red' }}>*** เข้ารับฝาก ***</b></div>
                                            </>
                                        }
                                      </>
                                      :
                                      <>
                                        {
                                          record.money >= record.PAYLIMITAMT ?
                                            <>
                                              <b style={{ fontSize: '14px', color: 'red' }}>** ยังไม่ได้รับเข้า **</b>
                                            </>
                                            :
                                            <>
                                              <div><b style={{ fontSize: '14px', color: 'red' }}>** ไม่ถึงขั้นต่ำ **</b></div>
                                              <div><b style={{ fontSize: '14px', color: 'red' }}>*** เข้ารับฝาก ***</b></div>
                                            </>
                                        }
                                      </>
                                  }
                                </>
                                :
                                <>
                                  <b style={{ fontSize: '14px', color: 'red' }}>** ยังไม่ได้รับเข้า **</b>
                                </>
                            }
                          </>
                          :
                          <b style={{ fontSize: '14px', color: 'red' }}>** เกินต้นคงเหลือ **</b>
                      }
                    </>
                }
              </>
          }
        </>
      ),
    },
    {
      title: "ลูกหนี้อื่นๆ",
      dataIndex: "AROTHR",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.AROTHR)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "รับฝาก",
      dataIndex: "TOTAMT",
      key: 'index',
      align: 'center',
      width: "auto",
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.TOTAMT)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      key: 'Action',
      title: "Action",
      // fixed: 'right',
      align: 'center',
      width: "10%",
      render: (record) => (
        <>
          {
            isClicked === 1 && record.idMysql === 0 ?
              <Space size="middle">
                <Button onClick={() => {
                  setIsModalInfo(true)
                  setConnt(record)
                }}><SearchOutlined /></Button>
              </Space>
              : null
          }
        </>
      ),
    },
  ];
  return <>
    <Card
      bordered={false}
      style={{
        width: 'auto',
      }}
    >
      <Row style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Spin spinning={loading} size='large' tip=" Loading... ">
            <Form>
              <div className='text-center'>
                <h2>รับเงิน (ออโต้)</h2>
              </div>
              <Row gutter={32} justify={'center'}>
                <Row gutter={24} justify={'center'} style={{ margin: 15 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกวันที่ต้องการ :"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={currentDateTime}
                        style={{ height: '40px', width: '200px' }}
                        disabledDate={(current) => current && current.isBefore(minDate, 'day')} // ปิดวันที่น้อยกว่า 18/12/2024
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      {/* <>{"ถึง"}</>
                    <DatePicker format={'YYYY-MM-DD'}
                      defaultValue={currentDateTime}
                      style={{ height: '40px' }}
                      onChange={(e) => {
                        if (e) {
                          setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                        }
                      }} /> */}
                      <Button disabled={loading} type='primary' onClick={handleSubmit} >ค้นหาสัญญา</Button>
                      <Button style={{ marginRight: '15px' }} disabled={loading || checkInsert} onClick={showConfirm} >คอมเฟิร์มสัญญาที่จะรับเข้า</Button>
                      {/* <Button style={{ marginRight: '15px' }} disabled={loading} onClick={testNoti} >ทดสอบแจ้งเตือน</Button> */}
                    </Space>
                  </Col>
                </Row>
              </Row>
              <Divider style={{ margin: 5 }} />
              <Row gutter={32} justify={'center'}>
                <Col span={7}>
                  <Card title="รายละเอียดสัญญา" bordered={false}>
                    <Row gutter={32} justify={'center'}>
                      <Col span={12}>
                        <Form.Item label="สัญญาทั้งหมด" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{resMix?.countAllContno}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                        </Form.Item>
                        <Divider />
                        <Form.Item label="สัญญาที่หาพบ" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{resMix?.countContnoT}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                        </Form.Item>
                        <Divider />
                        <Form.Item label="สัญญาหาไม่พบ" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{resMix?.countContnoF}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                        </Form.Item>
                        <Divider />
                      </Col>
                      <Col span={12}>
                        <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(resMix?.sumMoney)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Divider />
                        <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(resMix?.sumMoneyON)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Divider />
                        <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(resMix?.sumMoneyOFF)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Divider />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={10}>
                  <Card title="รายละเอียดการตัดเข้า" bordered={false}>
                    <Form labelCol={{ span: 8, }} wrapperCol={{ span: 16, }}>
                      <Row gutter={32} justify={'center'}>
                        <Col span={12}>
                          <Form.Item label="รับเข้าค่างวดได้" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{dataDetail.arr?.length}</b>
                            <span style={{ display: 'inline-block', marginLeft: '8px' }}> สัญญา</span>
                          </Form.Item>
                          <Form.Item label="เข้ารับฝากได้" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{arrayTableNopay?.length}</b>
                            <span style={{ display: 'inline-block', marginLeft: '8px' }}> สัญญา</span>
                          </Form.Item>
                          <Form.Item label="เกินเงินต้น" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{arrayTableNopayTON?.length}</b>
                            <span style={{ display: 'inline-block', marginLeft: '8px' }}> สัญญา</span>
                          </Form.Item>
                          <Form.Item label="ส่งชุดนอก" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{arrayTableTNOTEFL?.length}</b>
                            <span style={{ display: 'inline-block', marginLeft: '8px' }}> สัญญา</span>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataDetail?.money)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '8px' }}> บาท</span>
                          </Form.Item>
                          <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataDetail?.moneyNopay)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '8px' }}> บาท</span>
                          </Form.Item>
                          <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataDetail?.moneyNopayTON)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '8px' }}> บาท</span>
                          </Form.Item>
                          <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataDetail?.sumAmountTNOTEFL)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '8px' }}> บาท</span>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="รวมทั้งหมด" style={{ margin: 5 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataDetail?.money + dataDetail?.moneyNopay + dataDetail?.moneyNopayTON + dataDetail?.sumAmountTNOTEFL)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '8px' }}> บาท</span>
                          </Form.Item>
                        </Col>
                        <Divider />
                        <Col span={12}>
                          {/* <Form.Item label="สัญญาที่เลือก" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{selectedRows?.length}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                        </Form.Item> */}
                          <Form.Item label="เข้าค่างวด" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{selectedRows?.length}</b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                          </Form.Item>
                          <Form.Item label="เข้ารับฝาก" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{selectedRowsHD?.length}</b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}> สัญญา</span>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          {/* <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(checkMoney)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item> */}
                          <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(checkMoney)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                          </Form.Item>
                          <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(checkMoneyHD)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="รวมทั้งหมด" style={{ margin: 0 }}>
                            <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(checkMoney + checkMoneyHD)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </Col>
                <Col span={7}>
                  <Card title="รายละเอียดที่รับเข้า" bordered={false}>
                    <Row gutter={32} justify={'center'}>
                      <Col span={12}>
                        <Form.Item label="เข้าค่างวด" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{dataDetail.AMT?.length}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> ใบ</span>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataDetail?.sumMoneysAMT)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="เข้ารับฝาก" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{dataDetail.HD?.length}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> ใบ</span>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataDetail?.sumMoneysHD)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                      </Col>
                      <Divider />
                      <Col span={12}>
                        <Form.Item label="ใบเสร็จ" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{dataDetail?.countCont}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> ใบ</span>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="ยอดรวม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataDetail?.sumMoneys)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Divider style={{ margin: 5 }} />
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  {
                    isClicked === 1 ?
                      <Input.Search
                        style={{ width: '300px' }}
                        placeholder="ค้นหาสัญญา...."
                        onChange={search}
                        name="search"
                        id="search"
                        size="large"
                        value={query}
                      />
                      : null
                  }
                </Col>
              </Row>
              <Row style={{ margin: 10 }}>
                <Col span={24}>
                  <div className='text-right'>
                    <Space>
                      {/* {
                        arrayTable?.length > 0 ?
                          <> */}
                      <Button onClick={() => buttonAmt(1)} style={{ background: isClicked === 1 ? '#00FF00' : 'white' }}>สัญญาที่หาพบ</Button>
                      <Button onClick={() => buttonAmt(3)} style={{ background: isClicked === 3 ? '#00FF00' : 'white' }}>สัญญาที่เข้ารับฝาก</Button>
                      <Button onClick={() => buttonAmt(2)} style={{ background: isClicked === 2 ? '#00FF00' : 'white' }}>สัญญาที่หาไม่พบ</Button>
                      <Button onClick={() => buttonAmt(5)} style={{ background: isClicked === 5 ? '#00FF00' : 'white' }}>สัญญาที่ยอดเกินเงินต้น</Button>
                      <Button onClick={() => buttonAmt(6)} style={{ background: isClicked === 6 ? '#00FF00' : 'white' }}>สัญญาที่ส่งชุดนอก</Button>
                      <Button onClick={() => buttonAmt(4)} style={{ background: isClicked === 4 ? '#00FF00' : 'white' }}>สัญญาที่รับเข้าค่างวดแล้ว</Button>
                      <Button onClick={() => buttonAmt(7)} style={{ background: isClicked === 7 ? '#00FF00' : 'white' }}>สัญญาที่รับเข้ารับฝากแล้ว</Button>
                      {/* </>
                          : null
                      } */}
                    </Space>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row gutter={32}>
                <Col span={24}>
                  {
                    dataStatus === 205 ?
                      <><b style={{ fontSize: '14px', color: 'red' }}>{"***** ระบบกำลังดำเนินการอยู่ *****"}</b></>
                      :
                      <>
                        {
                          isClicked === 1 ?
                            <Table
                              rowKey={(record) => record.keyId}
                              scroll={{
                                x: 1500,
                                y: 400,
                              }}
                              dataSource={arrayTable}
                              columns={columns}
                            >
                            </Table>
                            :
                            isClicked === 2 ?
                              <Table
                                rowKey={(record) => record.keyId}
                                scroll={{
                                  x: 1500,
                                  y: 400,
                                }}
                                dataSource={arrayTableMiss}
                                columns={columnsMiss}
                              >
                              </Table>
                              :
                              isClicked === 3 ?
                                <Table
                                  rowKey={(record) => record.keyId}
                                  scroll={{
                                    x: 1500,
                                    y: 400,
                                  }}
                                  dataSource={arrayTableNopay}
                                  columns={columns}
                                >
                                </Table>
                                :
                                isClicked === 4 ?
                                  <Table
                                    rowKey={(record) => record.keyId}
                                    scroll={{
                                      x: 1500,
                                      y: 400,
                                    }}
                                    dataSource={dataDetail?.AMT}
                                    columns={columns}
                                  >
                                  </Table>
                                  :
                                  isClicked === 5 ?
                                    <Table
                                      rowKey={(record) => record.keyId}
                                      scroll={{
                                        x: 1500,
                                        y: 400,
                                      }}
                                      dataSource={arrayTableNopayTON}
                                      columns={columns}
                                    >
                                    </Table>
                                    :
                                    isClicked === 6 ?
                                      <Table
                                        rowKey={(record) => record.keyId}
                                        scroll={{
                                          x: 1500,
                                          y: 400,
                                        }}
                                        dataSource={arrayTableTNOTEFL}
                                        columns={columns}
                                      >
                                      </Table>
                                      :
                                      isClicked === 7 ?
                                        <Table
                                          rowKey={(record) => record.keyId}
                                          scroll={{
                                            x: 1500,
                                            y: 400,
                                          }}
                                          dataSource={dataDetail?.HD}
                                          columns={columns}
                                        >
                                        </Table>
                                        : <><b style={{ fontSize: '14px', color: 'red' }}>{"***** ไม่พบข้อมูล *****"}</b></>
                        }
                      </>
                  }
                  <Divider />
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
      </Row>
    </Card >
    {
      isModalInfo ?
        <DetailContno
          open={isModalInfo}
          close={setIsModalInfo}
          dataFromTable={connt}
        />
        : null
    }
    {contextHolder}
  </>
}

