import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Form, Modal, Row, Space, Spin, Upload, message, notification } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react'
import axios from 'axios';
import dayjs from 'dayjs';
import Compressor from 'compressorjs';
import { approvepgcon, approvepgnew, pguploadcar, pguploadland, rejectpg, unapprovepgnew } from '../../../../file_mid/all_api';

function Page2({ pages, setSaveData, setPages, SendBack, appStatus, saveData, close, closeall }) {

    const [form] = Form.useForm()
    const [dataNoteForPG, setDataNoteForPG] = useState()
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [loading, setLoading] = useState(false)
    const [fileListImgNoteForPg, setFileListImgNoteForPg] = useState([]);
    const [allImg, setAllImg] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const newFormData = new FormData();

    const overSizeIMG = (placement, name) => {
        api.error({
            message: `รูปภาพเกินขนานที่กำหนด`,
            description:
                `ไม่สามารถเลือกรูปภาพ ${name} ได้เนื่องจากเกินขนาดที่กำหนด`,
            placement,
        });
    };

    const insertSuccess = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            placement,
        });
    };

    const insertError = (placement) => {
        api.error({
            message: `บันทึกไม่สำเร็จ`,
            placement,
        });
    };
    const imageWarning = (placement) => {
        api.warning({
            message: `กรุณาเพิ่มรูปภาพอย่างน้อย 1 รูป`,
            placement,
        });
    };

    const uploadSuccess = (placement) => {
        api.success({
            message: `บันทึกรูปภาพสำเร็จ`,
            placement,
        });
    };

    const uploadError = (placement) => {
        api.error({
            message: `บันทึกรูปภาพไม่สำเร็จ`,
            placement,
        });
    };

    const handleChangePage = () => {
        setPages(pages - 1);
    };

    const handleClose = () => {
        close()
    };

    const handleSubmit = () => {
        // if (allImg?.length > 0) {
        if (appStatus === "Reject") {
            sendReject(saveData)
        } else if (appStatus === "อนุมัติ") {
            sendNote(saveData)
        } else if (appStatus === "อนุมัติแบบมีเงื่อนไข") {
            sendApprovedConditions(saveData)
        } else if (appStatus === "ไม่อนุมัติ") {
            sendUnApproved(saveData)
        }
        // } else {
        //     imageWarning("top")
        // }
    };

    const sendReject = async (value) => { // reject
        if (allImg?.length > 0) {
            setLoading(true)
            await axios.post(rejectpg, { ...value, noteForPg: dataNoteForPG })
                .then(res => {
                    if (res.status === 200) {
                        insertSuccess('top')
                        if ((allImg?.length > 0) && (res.data)) {
                            imgSend(allImg, res.data)
                        } else {
                            setTimeout(() => {
                                closeall(false)
                            }, 1000);
                        }
                    }
                }).catch((err) => {
                    insertError('top')
                    console.log(err)
                    setLoading(false)
                })
        } else {
            imageWarning("top")
        }
    };

    const sendNote = async (value) => { // อนุมัติ
        setLoading(true)
        // console.log("value +++++++++++++++;", value)
        await axios.put(approvepgnew, { ...value, noteForPg: dataNoteForPG })
            .then(res => {
                if (res.status === 200) {
                    insertSuccess('top')
                    if ((allImg?.length > 0) && (res.data)) {
                        imgSend(allImg, res.data)
                    } else {
                        setTimeout(() => {
                            closeall(false)
                        }, 1000);
                    }
                }
            }).catch((err) => {
                insertError('top')
                console.log(err)
                setLoading(false)
            })
    };

    const sendApprovedConditions = async (value) => { // อนุมัติแบบมีเงื่อนไข
        setLoading(true)
        await axios.put(approvepgcon, { ...value, noteForPg: dataNoteForPG })
            .then(res => {
                if (res.status === 200) {
                    insertSuccess('top')
                    if ((allImg?.length > 0) && (res.data)) {
                        imgSend(allImg, res.data)
                    } else {
                        setTimeout(() => {
                            closeall(false)
                        }, 1000);
                    }
                }
            }).catch((err) => {
                insertError('top')
                console.log(err)
                setLoading(false)
            })
    };

    const sendUnApproved = async (value) => { // ไม่อนุมัติ
        if (allImg?.length > 0) {
            // setLoading(true)
            console.log("ไม่อนุมัติ", { ...value, noteForPg: dataNoteForPG })
            await axios.put(unapprovepgnew, { ...value, noteForPg: dataNoteForPG })
                .then(res => {
                    if (res.status === 200) {
                        insertSuccess('top')
                        if ((allImg?.length > 0) && (res.data)) {
                            imgSend(allImg, res.data)
                        } else {
                            setTimeout(() => {
                                SendBack(false)
                                closeall(false);
                            }, 1000);
                        }
                    }
                }).catch((err) => {
                    insertError('top')
                    console.log(err)
                    setLoading(false)
                })
        } else {
            imageWarning("top")
        }
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const beforeUpts = async (file, ty) => {
        try {
            setLoading(true)
            const compressedFile = await compressImage(file, ty);
            return Promise.resolve(compressedFile);
        } catch (error) {
            console.error('Image compression error:', error);
            message.error('Image compression failed.');
            return Promise.reject(); // เปลี่ยนให้ return Promise.reject() เมื่อไม่สามารถบีบอัดได้
        }
    };

    const imgSend = (resultImgs, id) => {
        setLoading(true);
        if (id) {
            if (saveData?.typeLoan === "car") {
                return new Promise(async (resolve, reject) => {
                    try {
                        var status;
                        newFormData.append('idproduct', saveData?.carId);
                        newFormData.append('idnote', id); // id note ที่ได้มาจากหลังบ้าน
                        for (const file of resultImgs) {
                            var image = {}
                            newFormData.append('file', file.imageCompressedFile);
                            newFormData.append("type", file.type);
                            image.type = file.type
                            // resultImage.push(image)
                        }
                        const response = await axios.post(pguploadcar, newFormData);
                        if (response.data) {
                            console.log("TY", response.data);
                            uploadSuccess("top")
                            status = response.status;
                            resolve(status);
                            setTimeout(() => {
                                closeall(false)
                            }, 1000);
                        }
                    } catch (error) {
                        setLoading(false);
                        reject(error);
                        uploadError("top")
                    } finally {
                        setLoading(false);
                        setFileListImgNoteForPg([]);
                        form.setFieldsValue({
                            fileListImgNoteForPg: [],
                        })
                        console.log("OK")
                    }
                });
            } else {
                return new Promise(async (resolve, reject) => {
                    try {
                        var status;
                        newFormData.append('idproduct', saveData?.carId);
                        newFormData.append('idnote', id); // id note ที่ได้มาจากหลังบ้าน

                        for (const file of resultImgs) {
                            var image = {}
                            newFormData.append('file', file.imageCompressedFile);
                            newFormData.append("type", file.type);
                            image.type = file.type
                            // resultImage.push(image)
                        }
                        const response = await axios.post(pguploadland, newFormData);
                        if (response.data) {
                            //success(randomTenDigitNumber);
                            console.log("TY", response.data);
                            uploadSuccess("top")
                            status = response.status;
                            resolve(status);
                            setTimeout(() => {
                                closeall(false)
                            }, 1000);
                        }
                    } catch (error) {
                        setLoading(false);
                        reject(error);
                        uploadError("top")
                    } finally {
                        setLoading(false);
                        setFileListImgNoteForPg([]);
                        form.setFieldsValue({
                            fileListImgNoteForPg: [],
                        })
                        console.log("OK")

                    }
                });
            }
        } else {
            uploadError("top")
        }
    };

    const compressImage = async (ez, types) => {
        var image = {};
        return new Promise(async (resolve) => {
            if (types !== 7) {
                // console.log("ez", ez)
                if (ez.size > 5000000) {
                    overSizeIMG("top", ez.name)
                    // console.log("4m ไม่ให้ส่ง");
                    resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                } else {
                    let quality;
                    if (ez.size >= 2500000 && ez.size <= 4000000) {
                        quality = 0.1;
                    } else if (ez.size >= 2000000 && ez.size <= 2499999) {
                        quality = 0.15;
                    } else if (ez.size >= 1500000 && ez.size <= 1999999) {
                        quality = 0.2;
                    } else if (ez.size >= 1000000 && ez.size <= 1499999) {
                        quality = 0.3;
                    } else {
                        quality = 0.5;
                    }

                    try {
                        // ใช้ Promise เพื่อบีบอัดรูปภาพ
                        const compressedFile = await new Promise((resolveCompress) => {
                            new Compressor(ez, {
                                quality: quality,
                                success: (result) => {
                                    resolveCompress(result);
                                },
                            });
                        });

                        const fileOptions = {
                            type: compressedFile.type,
                            lastModified: Date.now(),
                        };
                        const compressedFileAsFile = new File([compressedFile], ez.name, fileOptions);
                        image.imageCompressedFile = compressedFileAsFile;
                        image.type = types
                        allImg.push(image);
                        //setAllImg((prevAllImg) => [...prevAllImg, image]);

                        // ตรงนี้คือการ resolve ไฟล์ที่ถูกบีบอัด
                        resolve(image);
                    } catch (error) {
                        console.error('Image compression error:', error);
                        resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
                    }
                }
            } else {
                // ... (โลจิกสำหรับ type 7)
                image.imageCompressedFile = ez;
                image.type = types
                allImg.push(image);
                //setAllImg((prevAllImg) => [...prevAllImg, image]);
                resolve(true);  // บอกให้ระบบทราบว่ากระบวนการเสร็จสิ้น
            }
        });
    };

    const handleImageNoteForPg = async ({ fileList }) => {
        for (const removedFile of fileListImgNoteForPg.filter(file => file.status === 'removed')) {
            if (allImg.some(img => img.imageCompressedFile.name === removedFile.name && img.type === 15)) {
                const updatedAllImg = allImg.filter(img => !(img.imageCompressedFile.name === removedFile.name && img.type === 15));
                setAllImg(updatedAllImg);
            }
        }

        // กรองไฟล์ที่มีขนาดไม่เกิน 5MB
        const filteredFileList = fileList.filter(e => e.size <= 5000000);

        // อัปเดตสถานะ fileListImgNoteForPg ด้วยไฟล์ที่ถูกกรองแล้ว
        setFileListImgNoteForPg(filteredFileList);

        // setFileListImgNoteForPg(fileList);
        setLoading(false)
    };

    const handleCancelPreview = () => {
        setPreviewVisible(false);
    }

    const handleSetNoteForPG = (value) => {
        setDataNoteForPG({
            carLandId: saveData?.carId,
            note: value,
            noteby: saveData?.noteBy,
            status: saveData?.status,
            rejectStatus: saveData?.rejectStatus,
            noteDate: dayjs()
        })
    }

    return (
        <Card>
            <Form
                onFinish={handleSubmit}
                form={form}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 16,
                }}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Row gutter={32} justify={'center'}>
                        <Col span={22}>
                            <Form.Item
                                label="หมายเหตุถึงธุรการ"
                                name="note"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณากรอกหมายเหตุถึงธุรการ',
                                    }
                                ]}
                            >
                                <TextArea rows={4} onChange={(e) => handleSetNoteForPG(e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Divider orientation='left'><b><u>เพิ่มรูป (อัพโหลดไฟล์ไม่เกิน 5MB)</u></b></Divider>
                        {
                            <div className='gutter-row'>
                                <b>
                                    <Form.Item label='เพิ่มรูป' name='imageIDGuCar'>
                                        <Upload
                                            name='imageIDGuCar'
                                            multiple={true}
                                            listType="picture-card"
                                            fileList={fileListImgNoteForPg}
                                            onPreview={handlePreview}
                                            onChange={handleImageNoteForPg}
                                            beforeUpload={(file) => beforeUpts(file, 15).then(() => false).catch(() => true)}
                                        >
                                            <div>
                                                <PlusOutlined />
                                                <div className="ant-upload-text">Upload</div>
                                            </div>
                                        </Upload>
                                        <Modal open={previewVisible} onCancel={handleCancelPreview}>
                                            <img alt="ตัวอย่างรูปภาพ" style={{ width: '100%' }} src={previewImage} />
                                        </Modal>
                                    </Form.Item>
                                </b>
                            </div>
                        }
                        <Divider />
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Space>
                                <Button type='primary' onClick={handleChangePage} style={{ backgroundColor: 'gray' }}>ย้อนกลับ</Button>
                                <Button type='primary' onClick={handleClose} danger >ปิด</Button>
                                <Button type='primary' htmlType='submit' style={{ backgroundColor: 'green' }}>บันทึก</Button>
                            </Space>
                        </Col>
                    </Row>
                </Spin>
            </Form>
            {contextHolder}
        </Card>
    )
}

export default Page2