import React, { useState, useEffect } from "react";
import { Button, Col, Input, Row, Table, Spin, Space, Card, Divider, Select, Tag, message, } from "antd";
import { FileSearchOutlined, ProfileOutlined, CheckOutlined, SearchOutlined, } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import ModalInstallment from "../../check_info_PG_RE/modals/ModalInstallment";
import BillExpedite from "./modals/BillExpedite";
import "../../css/MobileActive.css";
import WaitApproval from "./modals/WaitApproval";
import "../../css/buttonNickie.css";
import "../../css/Media.css";
import { loadMainBoss, mainexpeditethedebt, searchmainexpeditethedebt, } from "../../file_mid/all_api";

const Cars = () => {
  // const user = localStorage.getItem("username");
  const idPosition = localStorage.getItem("idSep");
  const [arrayTable, setArrayTable] = useState(); // ข้อมูลใน Table
  const [totalPage, setTotalPage] = useState();
  const [query, setQuery] = useState("");
  const [isModalInstallment, setIsModalInstallment] = useState(false);
  const [isModalInfoCar, setIsModalInfoCar] = useState(false);
  const [isModalRefinance, setIsModalRefinance] = useState(false);
  const [dataInstallment, setDataInstallment] = useState(null);
  const [dataInfoCar, setDataInfoCar] = useState(null);
  const [dataRefinance, setInfoRefinance] = useState(null);
  const [dataArr, setDataArr] = useState([]);
  const [dataSend, setDataSend] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [dataApproval, setDataApproval] = useState([]);
  const [selectedValue, setSelectedValue] = useState("reset");
  const [dataWait, setDataWait] = useState();
  const [dataSearch, setDataSearch] = useState();
  const [dataValue, setDataValue] = useState();
  const [queryContno, setQueryContno] = useState({ CONTNO: "" });
  const [bunsheClose, setBunsheClose] = useState([]);
  const [numCount3, setNumCount3] = useState(0);
  const [numCount4, setNumCount4] = useState(0);
  const [numCount2, setNumCount2] = useState(0);
  const [numCount28, setNumCount28] = useState(0);
  const [numCountAll, setNumCountAll] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (idPosition === "1" || idPosition === "5") {
      if (query !== "") {
        search();
      }
    }
    loadData()
  }, [dataValue]);

  const search = (event) => {
    onSearch(event.target.value);
  };

  const onSearch = (value) => {
    if (selectedValue === "reset") {
      let result = dataArr.filter(
        (item) =>
          item.CONTNO.includes(value) ||
          item.CNAME.includes(value) ||
          item.CUSCOD.includes(value) ||
          item.REGNO.includes(value) ||
          item.TELP.includes(value)
      );
      setArrayTable(result);
    } else if (selectedValue === "close") {
      let result = bunsheClose.filter(
        (item) =>
          item.CONTNO.includes(value)
      );
      setArrayTable(result);
    } else {
      let result = dataSearch.filter((item) => item.CONTNO.includes(value));
      setArrayTable(result);
    }
  };

  const onQuery = () => {
    if (queryContno) {
      queryData(queryContno);
    }
  };

  const onSelect = (value) => {
    setSelectedValue(value);
    if (idPosition === "1" || idPosition === "5") {
      if (value !== "reset") {
        handleSelectedStatus(value);
      } else {
        // loadData();
        setArrayTable(data)
      }
    }
  };

  const handleSelectedStatus = (value) => {
    if (value === "close") {
      const day = bunsheClose?.sort((a, b) => dayjs(b.approvalDate || b.date).diff(dayjs(a.approvalDate || a.date)));
      setArrayTable(day);
      setDataSearch(day);
    } else {
      let statusAll = dataArr.filter((item) => item.approvalStatus === value);
      const day = statusAll?.sort((a, b) => dayjs(b.approvalDate || b.date).diff(dayjs(a.approvalDate || a.date)));
      setArrayTable(day);
      setDataSearch(day);
    }
  };

  const loadData = async () => {
    setLoading(true);
    const tk = JSON.parse(token);
    const url = mainexpeditethedebt;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    await axios.get(url, { headers: headers })
      .then(async (res) => {
        let i = 1;
        if (res.data) {
          // console.log("res.dataaaaaaaaaaaaaa", res.data)
          const newData = res.data.map((item) => ({
            ...item,
            key: i++,
          }));
          setDataArr(newData);
          setTotalPage(res.data.length);
          loadCheckClose(newData)
          const sortedData = res?.data
          setArrayTable(sortedData)
          setData(sortedData)
          let sortedDataGroups = {
            0: [],
          }
          sortedData?.map((a, i) => {
            if (a?.approvalStatus === 0) {
              sortedDataGroups[0].push(a)
            }
          })
          setNumCountAll(sortedDataGroups[0]?.length || 0);
          if (selectedValue === 0) {
            const sort0 = sortedDataGroups[0]
            setArrayTable(sort0);
          }
        } else {
          setArrayTable([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const loadCheckClose = async (e) => {
    console.log('e', e)
    setLoading(true);
    const tk = JSON.parse(token);
    const urlStatus = loadMainBoss;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    const statusBody = { approvalStatus: 0 };
    await axios.post(urlStatus, { statusBody }, { headers: headers })
      .then(async (res) => {
        if (res.status === 200) {
          // console.log("res.data11111111111", res.data)
          // setArrayTable(res?.data)
          // setData(res?.data)
          const sortedDatacount = res?.data?.count
          const sortedData = res?.data?.data
          let sortedDataGroups = {
            3: 0,
            4: 0,
            2: 0,
            28: 0,
          }
          let sortedCountGroups = {
            3: 0,
            4: 0,
            2: 0,
            28: 0,
          }
          sortedDatacount?.map((a, i) => {
            // console.log("a", a)
            if (a?.approvalStatusId === "3") {
              sortedCountGroups[3] = a.count
            } else if (a?.approvalStatusId === "4") {
              sortedCountGroups[4] = a.count = a.count
            } else if (a?.approvalStatusId === "2") {
              sortedCountGroups[2] = a.count
            } else if (a?.approvalStatusId === "28") {
              sortedCountGroups[28] = a.count
            }
          })
          sortedData?.map((a, i) => {
            // console.log("a", a)
            if (a?.approvalStatusId === "3") {
              sortedDataGroups[3] = a.count
            } else if (a?.approvalStatusId === "4") {
              sortedDataGroups[4] = a.count = a.count
            } else if (a?.approvalStatusId === "2") {
              sortedDataGroups[2] = a.count
            } else if (a?.approvalStatusId === "28") {
              sortedDataGroups[28] = a.count
            }
          })
          setNumCount3(sortedCountGroups[3]);
          setNumCount4(sortedCountGroups[4]);
          setNumCount2(sortedCountGroups[2]);
          setNumCount28(sortedCountGroups[28]);

          if (selectedValue === 4) {
            const sort4 = sortedDataGroups[4]
            // console.log("sort4",sort4)
            setArrayTable(sort4);
          } else if (selectedValue === 3) {
            const sort3 = sortedDataGroups[3]
            // console.log("sort4",sort3)
            setArrayTable(sort3);
          } else if (selectedValue === 2) {
            const sort2 = sortedDataGroups[2]
            // console.log("sort4",sort2)
            setArrayTable(sort2);
          } else if (selectedValue === 28) {
            const sort28 = sortedDataGroups[28]
            // console.log("sort4",sort28)
            setArrayTable(sort28);
          }
          handleCheckClose(e, res?.data?.data)
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handleCheckClose = async (stepOne, stepTwo) => {
    const unmatchedInStepTwo = stepTwo.filter(itemTwo =>
      !stepOne.some(itemOne =>
        itemOne.CONTNO === itemTwo.CONTNO
      )
    );
    let i = 1;
    const dataClose = unmatchedInStepTwo.map((item) => ({
      ...item,
      key: i++,
    }));
    setBunsheClose(dataClose)
  };

  const queryData = async () => {
    setLoading(true);
    const tk = JSON.parse(token);
    const urlQueryData = searchmainexpeditethedebt;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    await axios.post(urlQueryData, { CONTNO: queryContno }, { headers })
      .then(async (resQuery) => {
        if (resQuery.data) {
          setArrayTable(resQuery.data);
          setTotalPage(resQuery.data.length);
          console.log(resQuery.data);
        } else {
          setArrayTable([]);
          setTotalPage([]);
          message.error("ไม่มีเลขที่สัญญาที่ค้นหา");
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };


  const handleChangeStatus = (data, status) => {
    setDataValue(status)
    console.log("TEST", data, status);
    const result = dataArr.map((item) => {
      if (item.CONTNO === data.CONTNO) {
        return { ...data };
      } else {
        return { ...item };
      }
    });
    setDataArr(result);
    // handleStatusApproval(result);
    if (status === "reset") {
      setArrayTable(result);
    } else {
      const arr = result.filter((item) =>
        item.ApprovalStatus === status
      );
      setArrayTable(arr);

    }
  };

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: "10%",
      render: (text, object, key) => key + 1,
      sorter: {
        compare: (a, b) => a.key - b.key,
        multiple: 5,
      },
    },
    {
      title: "รายละเอียด",
      dataIndex: "CONTNO",
      key: "CONTNO",
      align: "center",
      width: "40%",
      render: (text, record1) => {
        const dataNew = () => {
          return (
            <>
              <div> เลขสัญญา <b>{record1?.CONTNO ? record1?.CONTNO : null}</b> </div>
              <div>{record1.CNAME ? <>{record1.CNAME ? record1.CNAME : null}</> : <>{record1.snam && record1?.firstname && record1?.lastname ? record1.snam + ' ' + record1?.firstname + ' ' + record1?.lastname : null}</>}</div>
              {
                record1.BAAB || record1.TYPE || record1.MODEL || record1.MANUYR || record1.REGNO ?
                  <div>
                    {record1.BAAB ? record1.BAAB : null}
                    {record1.TYPE ? record1.TYPE : null}
                    {record1.MODEL ? record1.MODEL + 'ปีรถ' : null} <br />
                    {record1.MANUYR ? parseInt(record1.MANUYR) + 'เลขทะเบียน' : null}
                    {record1.REGNO ? record1.REGNO : null}
                  </div>
                  :
                  null
              }
              <div>เสนอโดย <Space style={{ color: "red" }}><b>{record1.SALCOD ? record1.SALCOD : record1?.proposalBy}</b></Space></div>
            </>
          )
        }

        return (
          <div>

            {record1?.approvalStatus === 3 ? (
              <div>
                {dataNew()}
                วันที่เฮียอนุมัติ <Space style={{ color: "green" }}>
                  <>
                    <Space style={{ color: "red" }}>
                      {dayjs(record1.approvalDate).format("DD-MM-YYYY")}
                    </Space>
                  </>
                </Space>
              </div>
            ) : record1?.approvalStatus === 4 ? (
              <div>
                {dataNew()}
                วันที่เฮียอนุมัติ <Space style={{ color: "red" }}>
                  <>
                    <Space style={{ color: "red" }}>
                      {dayjs(record1.approvalDate).format("DD-MM-YYYY")}
                    </Space>
                  </>
                </Space>
              </div>
            ) : selectedValue === 'close' ? (
              <div>
                {dataNew()}
                วันที่ส่งหา PG <Space style={{ color: "red" }}>
                  <Space style={{ color: "red" }}>
                    {record1?.sendPgDate ? dayjs(record1?.sendPgDate).format("DD-MM-YYYY HH:mm:ss") : null}
                  </Space>
                </Space>
              </div>
            ) :
              <div>
                {dataNew()}
              </div>
            }
          </div >
        );
      }
    },
    {
      title: "สถานะสัญญา",
      dataIndex: "ApprovalStatus",
      key: "ApprovalStatus",
      align: "center",
      width: "30%",
      // sorter: {
      //   compare: (a, b) => a.approvalStatus.length - b.approvalStatus.length,
      //   multiple: 5,
      // },
      render: (text, record) => (
        <>
          {
            selectedValue !== "close" ?
              <>
                {idPosition === "1" || idPosition === "5" ? (
                  <>
                    {
                      record.approvalStatus === 0 ? (
                        <Tag style={{ padding: 5 }}>
                          <b>ยังไม่ได้ส่งขอ</b>
                        </Tag>
                      ) : record.approvalStatus === 4 ? (
                        <Tag style={{ backgroundColor: "blue", color: 'white', padding: 5 }}>
                          <b>ส่งเคสหาพี่กั้ง</b>
                        </Tag>
                      ) : record.approvalStatus === 2 ? (
                        <Tag style={{ backgroundColor: "yellow", padding: 5 }}>
                          <b>รอCEOอนุมัติ</b>
                        </Tag>
                      ) : record.approvalStatus === 28 ? (
                        <Tag style={{ backgroundColor: "orange", padding: 5 }}>
                          <b>รอนำเสนอCEO</b>
                        </Tag>
                      ) : record.approvalStatus === 3 ? (
                        <Tag style={{ backgroundColor: "#dcedc8", padding: 5 }}>
                          <b>รอส่งเคสอนุมัติ</b>
                        </Tag>
                      ) : null}
                  </>
                ) : (
                  "-"
                )}
              </>
              :
              <>
                <Tag style={{ backgroundColor: "pink" }}>
                  <b>สัญญาที่ปิดแล้ว</b>
                </Tag>
              </>
          }

        </>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Row gutter={32} style={{ textAlign: "center" }}>
          <Col>
            <Spin spinning={loading} size="large" tip=" Loading... ">
              <Col>
                <div className="text-center">
                  <h2>ตรวจสอบข้อมูลตามงวด</h2>
                </div>
              </Col>
              <Row gutter={32} className="main" justify={'center'}>
                {idPosition === "1" || idPosition === "5" ? (
                  <Col span={18} >
                    <aside>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                          alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                          justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง

                        }}
                      >
                        <Tag
                          style={{
                            backgroundColor: "#f5f5f5",
                            margin: "5px",
                            width: '160px',
                            height: '30px',
                            display: 'flex', // ใช้ Flexbox
                            justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                            alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                            textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                            fontSize: '15px'
                          }}
                          onClick={() => {
                            handleSelectedStatus(0);
                            setSelectedValue(0);
                          }}
                        >
                          <b>{"ยังไม่ได้ส่งขอ "}
                            {numCountAll}
                            {" "}
                            เคส</b>
                        </Tag>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                          alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                          justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง
                        }}
                      >
                        <Tag
                          style={{
                            backgroundColor: "orange",
                            margin: "5px",
                            width: '160px',
                            height: '30px',
                            display: 'flex', // ใช้ Flexbox
                            justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                            alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                            textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                            fontSize: '15px'
                          }}
                          onClick={() => {
                            handleSelectedStatus(28);
                            setSelectedValue(28);
                          }}
                        >
                          <b>{"รอCEOรับงาน "}
                            {numCount28}
                            {" "}
                            เคส</b>
                        </Tag>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                          alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                          justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง

                        }}
                      >
                        <Tag
                          style={{
                            backgroundColor: "yellow",
                            margin: "5px",
                            width: '160px',
                            height: '30px',
                            display: 'flex', // ใช้ Flexbox
                            justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                            alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                            textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                            fontSize: '15px'
                          }}
                          onClick={() => {
                            handleSelectedStatus(2);
                            setSelectedValue(2);
                          }}
                        >
                          <b>{"รอCEOอนุมัติ "}
                            {numCount2}
                            {" "}
                            เคส</b>
                        </Tag>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                          alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                          justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง
                        }}
                      >
                        <Tag
                          style={{
                            backgroundColor: "#dcedc8",
                            margin: "5px",
                            width: '160px',
                            height: '30px',
                            display: 'flex', // ใช้ Flexbox
                            justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                            alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                            textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                            fontSize: '15px'
                          }}
                          onClick={() => {
                            handleSelectedStatus(3);
                            setSelectedValue(3);
                          }}
                        >
                          <b>{"รอส่งเคสอนุมัติ "}
                            {numCount3}
                            {" "}
                            เคส</b>
                        </Tag>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                          alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                          justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง
                        }}
                      >
                        <Tag
                          style={{
                            backgroundColor: "#72f70d",
                            margin: "5px",
                            width: '160px',
                            height: '30px',
                            display: 'flex', // ใช้ Flexbox
                            justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                            alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                            textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                            fontSize: '15px'
                          }}
                          onClick={() => {
                            handleSelectedStatus(4);
                            setSelectedValue(4);
                          }}
                        >
                          <b>{"สำเร็จ "}
                            {numCount4}
                            {" "}
                            เคส</b>
                        </Tag>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column', // จัดวาง div แต่ละอันในแนวตั้ง
                          alignItems: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
                          justifyContent: 'center', // จัดให้อยู่ตรงกลางในแนวตั้ง
                        }}
                      >
                        <Tag
                          style={{
                            backgroundColor: "pink",
                            margin: "5px",
                            width: '160px',
                            height: '30px',
                            display: 'flex', // ใช้ Flexbox
                            justifyContent: 'center', // จัดกึ่งกลางในแนวนอน
                            alignItems: 'center', // จัดกึ่งกลางในแนวตั้ง
                            textAlign: 'center', // จัดการข้อความให้อยู่ตรงกลาง
                            fontSize: '15px'
                          }}
                          onClick={() => {
                            handleSelectedStatus("close");
                            setSelectedValue("close");
                          }}
                        >
                          <b>{"สัญญาที่ปิดแล้ว "}
                            {bunsheClose ? bunsheClose?.length : 0}
                            {" "}
                            เคส
                          </b>
                        </Tag>
                      </div>
                    </aside>
                  </Col>
                ) : null}
              </Row>
              <Row justify={'center'}>
                <Col span={24}>
                  <div>
                    <b
                      onClick={() => {
                        onSelect("reset");
                        setSelectedValue("reset");
                      }}
                      style={{
                        fontSize: '15px'
                      }}
                    >
                      จำนวนทั้งหมด {totalPage ? totalPage : "0"} {" เคส"}
                    </b>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginLeft: "5px" }} justify={'center'}>
                <Col
                  span={24}
                >
                  {idPosition === "1" || idPosition === "5" ? (
                    <>
                      <Space
                        wrap
                        style={{ margin: "5px" }}
                      >
                        <Select
                          defaultValue={<b>ทั้งหมด</b>}
                          style={{
                            width: 280,
                            height: '40px'
                          }}
                          value={selectedValue}
                          onChange={onSelect}
                          options={[
                            {
                              value: "reset",
                              label: "ทั้งหมด",
                            },
                            {
                              value: 0,
                              label: "เคสที่ยังไม่ส่ง",
                            },
                            {
                              value: 28,
                              label: "รอนำเสนอCEO",
                            },
                            {
                              value: 2,
                              label: "รอCEOอนุมัติ",
                            },

                            {
                              value: 3,
                              label: "รอส่งเคสอนุมัติ",
                            },
                            {
                              value: 4,
                              label: "สำเร็จ",
                            },
                            {
                              value: "close",
                              label: "สัญญาที่ปิดแล้ว",
                            },
                          ]}
                        />
                        <Input.Search
                          style={{ width: "300px", marginTop: "5px" }}
                          placeholder="ค้นหาเลขที่สัญญา"
                          onChange={search}
                          name="search"
                          id="search"
                        />
                      </Space>
                    </>
                  ) : (
                    <>
                      <Input
                        style={{ width: "60%" }}
                        placeholder="ค้นหาเลขที่สัญญา"
                        onChange={(e) => setQueryContno(e.target.value)}
                        name="search"
                        id="search"
                      />
                      <Button style={{ marginRight: "5px" }} onClick={onQuery}>
                        <SearchOutlined />
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  {
                    selectedValue !== "close" ?
                      <>
                        <Table
                          style={{ marginTop: "5px" }}
                          scroll={{
                            y: 400,
                          }}
                          expandable={{
                            expandedRowRender: (record) => {
                              if (record.CONTNO)
                                return (
                                  <div>
                                    <Space size="small">
                                      <Button
                                        title="ติดตามค่างวด"
                                        onClick={() => {
                                          setDataInfoCar(record.CONTNO);
                                          // console.log("dataInfoCar", dataInfoCar);
                                          // console.log("record.CONTNO", record.CONTNO);
                                          setIsModalInfoCar(true);
                                          setDataSend(record);
                                        }}
                                      >
                                        <FileSearchOutlined />
                                      </Button>
                                      <Button
                                        title="ตารางการผ่อน"
                                        onClick={() => {
                                          setDataInstallment(record.CONTNO);
                                          setIsModalInstallment(true);
                                          // console.log("ตารางการผ่อน", record.CONTNO);
                                        }}
                                      >
                                        <ProfileOutlined />
                                      </Button>
                                      {record.approvalStatus !== 0 &&
                                        (idPosition === "1" || idPosition === "5") ? (
                                        <Button
                                          title="ส่งอนุมัติรีไฟแนนซ์"
                                          onClick={() => {
                                            setInfoRefinance(record.CONTNO);
                                            setDataWait(record.idCarsApproveCeo);
                                            setIsModalRefinance(true);
                                            setDataApproval(record.MEMO1);
                                            setDataSend(record);
                                            // console.log("ส่งอนุมัติรีไฟแนนซ์");
                                          }}
                                        >
                                          <CheckOutlined />
                                        </Button>
                                      ) : null}
                                    </Space>
                                  </div>
                                );
                            },
                          }}
                          dataSource={arrayTable}
                          columns={columns}
                        ></Table>
                      </>
                      :
                      <>
                        <Table
                          style={{ marginTop: "5px" }}
                          scroll={{
                            y: 400,
                          }}
                          expandable={{
                            expandedRowRender: (record) => {
                              if (record.CONTNO)
                                return (
                                  <div>
                                    <Space size="small">
                                      {record.approvalStatus !== 0 &&
                                        (idPosition === "1" || idPosition === "5") ? (
                                        <Button
                                          title="ส่งอนุมัติรีไฟแนนซ์"
                                          onClick={() => {
                                            setInfoRefinance(record.CONTNO);
                                            setDataWait(record.id);
                                            setIsModalRefinance(true);
                                            setDataApproval(record.MEMO1);
                                            setDataSend(record);
                                            // console.log("ส่งอนุมัติรีไฟแนนซ์");
                                          }}
                                        >
                                          <CheckOutlined />
                                        </Button>
                                      ) : null}
                                    </Space>
                                  </div>
                                );
                            },
                          }}
                          dataSource={arrayTable}
                          columns={columns}
                        ></Table>
                      </>
                  }
                  <Divider />
                  <Row justify={"center"}></Row>
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {isModalInfoCar ?
        <BillExpedite
          open={isModalInfoCar}
          close={setIsModalInfoCar}
          continoBill={dataInfoCar}
          data={dataSend}
          onChangeStatus={handleChangeStatus}
          status={selectedValue}
        />
        : null}
      {isModalInstallment ?
        <ModalInstallment
          open={isModalInstallment}
          close={setIsModalInstallment}
          contno={dataInstallment}
        />
        : null}
      {isModalRefinance ?
        <WaitApproval
          open={isModalRefinance}
          close={setIsModalRefinance}
          continoRe={dataRefinance}
          id={dataWait}
          memo={dataApproval}
          data={dataSend}
          onChangeStatus={handleChangeStatus}
          status={selectedValue}
        />
        : null}
    </>
  );
};

export default Cars;
