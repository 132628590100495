import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Card, Divider, Tag, Row, Col, Collapse, Upload, Image, Spin } from 'antd'
import axios from 'axios';
import dayjs from 'dayjs';
import { accept, adsendratezero, checkcarad, checkcaradDB2, checklandad, getImagess, getImagessPre, loadimage } from '../../file_mid/all_api';
import { ApprovalStatusColor } from '../../file_mid/status_color';
import confirm from 'antd/es/modal/confirm';


function ModalAccept({ open, dataFromTable, close, Success, RateCar, RateZero, RateLand }) {
    const [loading, setLoading] = useState(false);
    const [dataModalEdit, setDataModalEdit] = useState(dataFromTable);
    const [statusAccept, setstatusAccept] = useState({
        customerId: '', carId: '', loanId: '', approvalStatus: '',
    });
    const token = localStorage.getItem('token');
    const [checkOld, setCheckOld] = useState(false);
    const [oldData, setOldData] = useState();
    const [getSize, setGetSize] = useState(500);
    const { Panel } = Collapse;
    const [oldDataDB2, setOldDataDB2] = useState();
    const [checkOldDB2, setCheckOldDB2] = useState();
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [countLoad, setCountLoad] = useState(0);

    useEffect(() => {
        loadImage();
        setDataModalEdit(dataFromTable)
        if (dataFromTable.car === "car") {
            loadDupDataCar()
            loadDupDataCarDB2()
            loadImage("car");
        } else {
            loadDupDataLand()
            loadImage("land");
        }
        pickData();
    }, [dataFromTable])


    const loadImage = async (type) => {
        setLoading(true)
        if (dataFromTable?.car === "car") {
            var param = { carId: dataFromTable?.carId }
        } else {
            var param = { LandId: dataFromTable?.carId }
        }

        await axios.post(loadimage, param)
            .then((res) => {
                setImageBlobzz(res.data); // Uncomment if you need to update state
                setLoading(false)
            }).catch((err) => {
                console.log("err", err)
            })

    };

    const handleCancel = () => {
        close(false)
    }


    const currencyFormatOne = (amount) => {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    const loadDupDataCar = async () => {
        // setLoading(true)
        // await axios.post(checkcarad, { carId: 653, carPlateNumber: "TEST-0058", carProvince: "ขอนแก่น" })
        await axios.post(checkcarad, { carId: dataFromTable.carId, carPlateNumber: dataFromTable.carPlateNumber, carProvince: dataFromTable.carProvince })
            .then(res => {
                if (res.status === 200) {
                    if (res.data !== null) {
                        // console.log('res', res.data);
                        setCheckOld(true)
                        setGetSize(1000)
                        setOldData(res.data)
                    }
                }
            }).catch((err) => console.log(err))
    };

    const loadDupDataCarDB2 = async () => {
        // setLoading(true)
        // await axios.post(checkcaradDB2, { STRNO: "MR053REE104106503", ENGNO: "1ZR-X043402" })
        await axios.post(checkcaradDB2, { STRNO: dataFromTable.carChassisNumber, ENGNO: dataFromTable.carEngineNumber, REGNO: dataFromTable.carPlateNumber, DORECV: dataFromTable.carProvince })
            .then(res => {
                if (res.status === 200) {
                    if (res.data !== null) {
                        // console.log('resDB2', res.data);
                        if (res.data.length > 0) {
                            setCheckOldDB2(true)
                            setGetSize(1000)
                        }
                        setOldDataDB2(res.data)
                    }
                }
            }).catch((err) => console.log(err))
    };

    const loadDupDataLand = async () => {
        // setLoading(true)
        // await axios.post(checklandad, { landId: 1920, numberLand: "11", provinces: "ยะลา", district: "เมืองยะลา" })
        await axios.post(checklandad, { landId: dataFromTable.carId, provinces: dataFromTable.carBrand, district: dataFromTable.carYear, numberLand: dataFromTable.carPlateNumber })
            .then(res => {
                if (res.status === 200) {
                    if (res.data !== null) {
                        setCheckOld(true)
                        setGetSize(1000)
                        setOldData(res.data)
                    }
                }
            }).catch((err) => console.log(err))
    };

    const handleOk = async () => {
        setLoading(true)
        await axios.put(accept, statusAccept)
            .then(res => {
                if (res.status === 200) {
                    if (dataFromTable.carPriceStatus === false) {
                        if (dataFromTable.car === "car") {
                            RateCar("top")
                        } else {
                            RateLand("top")
                        }
                        // setLoading(false)
                    } else {
                        Success("top")
                        // setLoading(false)
                    }
                    setTimeout(() => {
                        close(false);
                    }, 1000);
                }
            }).catch((err) => console.log(err))
        // setLoading(false)
    };

    const handleRateZero = (e, index) => {
        Modal.confirm({
            title: 'คุณต้องการที่จะส่งเรท 0 หรือไม่...?',
            okText: 'ใช่', // Custom "Yes" button text
            cancelText: 'ไม่ใช่', // Custom "No" button text
            onOk() {
                console.log('Confirmed: Sending rate zero');
                submitratezero();
            },
            onCancel() {
                console.log('Canceled: Did not send rate zero');
            },
        });
    };

    const submitratezero = async () => {
        setLoading(true)
        const param = {
            carId: dataFromTable?.carId,  // ตาราง car_loan_details
            loanId: dataFromTable?.loanId,  // ตาราง car_loan_details
            car_price: "0",  // ตาราง car
            car_price_status: "0",  // ตาราง car
        }
        await axios.put(adsendratezero, param)
            .then(res => {
                if (res.status === 200) {
                    RateZero("top")
                    setCountLoad(1)
                    setTimeout(() => {
                        close(false);
                    }, 1000);
                }
            }).catch((err) => console.log(err))
    }

    const pickData = () => {
        if (dataFromTable.car === "car") {
            if (dataFromTable.carPriceStatus === false) {
                setstatusAccept({
                    customerId: dataFromTable.customerId,
                    carId: dataFromTable.carId,
                    loanId: dataFromTable.loanId,
                    car: dataFromTable.car,
                    approvalStatus: 17,

                })
            } else {
                setstatusAccept({
                    customerId: dataFromTable.customerId,
                    carId: dataFromTable.carId,
                    loanId: dataFromTable.loanId,
                    car: dataFromTable.car,
                    // set เวลา
                    acceptDate: dayjs(),
                    approvalStatus: 18
                })
            }
        } else if (dataFromTable.car === "land") {
            setstatusAccept({
                customerId: dataFromTable.customerId,
                carId: dataFromTable.carId,
                loanId: dataFromTable.loanId,
                car: dataFromTable.car,
                // carDateStatus: formattedTime,

                // set เวลา
                acceptDate: dayjs(),
                approvalStatus: 18
            })
        }
    }

    const renderDupNote = ({ item, index }) => {
        return (
            <Row gutter={32} justify={'center'}>
                <Col className='gutter-row' span={24}>
                    <Card key={index} style={{ width: '270px', textAlign: 'left' }}>
                        <div style={{ marginBottom: 0 }}>
                            <span>สาเหตุ : </span>
                            {item?.cause}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>ผู้สร้างหมายเหตุ : </span>
                            {item?.noteBy}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>หมายเหตุ : </span>
                            {item?.note}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>วันที่หมายเหตุ : </span>
                            {dayjs(item?.noteDate).format("DD/MM/YYYY")}
                        </div>
                        <div style={{ marginBottom: 0 }}>
                            <span>สถานะ : </span>
                            <Tag color="gold">{item?.status}</Tag>
                        </div>
                    </Card >
                </Col>
            </Row>
        )
    }

    return (
        <div>
            <Modal title="การรับงาน" open={open} onCancel={handleCancel} width={getSize}
                footer={[
                    dataFromTable?.car === "car" ?
                        <>
                            {countLoad === 0 ?
                                <>
                                    <Button type='primary' style={{ background: 'blue' }} onClick={handleRateZero}>รถเป็นเรท 0</Button>
                                </>
                                :
                                <>
                                    <Button type='primary' style={{ background: 'blue' }} disabled onClick={handleRateZero}>รถเป็นเรท 0</Button>
                                </>
                            }
                        </>
                        :
                        null,

                    <Button type='primary' onClick={handleOk} disabled={loading} style={{ backgroundColor: "green" }}>ยืนยัน</Button>,
                    <Button type='primary' danger onClick={handleCancel}>ปิด</Button>,

                ]}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Card>
                        {
                            dataModalEdit?.car === "car" ?
                                <Divider orientation="left"><b>รายละเอียดรถ</b></Divider>
                                :
                                <Divider orientation="left"><b>รายละเอียดที่ดิน</b></Divider>
                        }
                        <Form
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}>
                            {
                                checkOld ?
                                    <>
                                        {
                                            dataModalEdit?.car === "car" ?
                                                <>
                                                    <Row>
                                                        <Col span={12}>
                                                            <Form.Item label="ชื่อลูกค้า" style={{ marginBottom: 0 }}>
                                                                {dataModalEdit?.snam}{dataModalEdit?.firstName} {dataModalEdit?.lastName}
                                                            </Form.Item>
                                                            <Form.Item label="เลขทะเบียนรถ" style={{ marginBottom: 0 }}>
                                                                {dataModalEdit?.carPlateNumber} {dataModalEdit?.carProvince}
                                                            </Form.Item>

                                                            <Form.Item label="เลขเครื่อง" style={{ marginBottom: 0 }}>
                                                                {dataModalEdit?.carEngineNumber ? (
                                                                    dataModalEdit.carEngineNumber
                                                                ) : (
                                                                    "ยังไม่ได้เพิ่มเลขเครื่อง"
                                                                )}
                                                            </Form.Item>
                                                            <Form.Item label="เลขคัสซี" style={{ marginBottom: 0 }}>
                                                                {dataModalEdit?.carChassisNumber ? (
                                                                    dataModalEdit.carChassisNumber
                                                                ) : (
                                                                    "ยังไม่ได้เพิ่มเลขคัสซี"
                                                                )}
                                                            </Form.Item>

                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                                                {dataModalEdit?.carBrand} {dataModalEdit?.carModel} {dataModalEdit?.carYear}
                                                            </Form.Item>
                                                            <Form.Item label="เรท" style={{ marginBottom: 0 }}>
                                                                {currencyFormatOne(dataModalEdit?.carPrice)}
                                                            </Form.Item>
                                                            <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                                                <Tag color="gold">{dataModalEdit?.approvalStatusName}</Tag>
                                                                <Tag color="green">{dataModalEdit?.productLoanType}</Tag>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Divider orientation="left"><b style={{ color: "red" }}>*พบข้อมูลที่เคสเสนอมาแล้ว!</b></Divider>
                                                    <Collapse>
                                                        {
                                                            oldData?.map((car, index) => (
                                                                <Panel
                                                                    header={
                                                                        <>
                                                                            <strong>วันที่เสนอ :</strong> {dayjs(car.carInput).format("DD/MM/YYYY")} <strong>อยู่ในสถานะ :</strong>
                                                                            <Tag color={ApprovalStatusColor(car?.carLoanDetails?.approvalStatus)}>{car?.carLoanDetails?.approvalStatusTable?.name}</Tag>
                                                                            {/* <span style={{color:'red'}}><strong>พบข้อมูล เลขคัสซี เลขเครื่อง หรือเลขทะเบียนรถตรงกัน!!</strong></span> */}
                                                                            <span style={{ color: 'red' }}>
                                                                                <strong>
                                                                                    {car?.carChassisNumber === dataModalEdit?.carChassisNumber && (
                                                                                        <span style={{ margin: '16px' }}>เลขคัสซีตรงกัน!</span>
                                                                                    )}
                                                                                    {car?.carEngineNumber === dataModalEdit?.carEngineNumber && (
                                                                                        <span style={{ margin: '16px' }}>เลขเครื่องตรงกัน!</span>
                                                                                    )}
                                                                                    {car?.carPlateNumber === dataModalEdit?.carPlateNumber && car?.carProvince === dataModalEdit?.carProvince && (
                                                                                        <span style={{ margin: '16px' }}>เลขทะเบียนตรงกัน!</span>
                                                                                    )}
                                                                                </strong>
                                                                            </span>
                                                                        </>
                                                                    }
                                                                    key={index}
                                                                >
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>ชื่อลูกค้า : </span></strong>
                                                                        {car?.customer?.snam}{car.customer?.firstname} {car.customer?.lastname}
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>เลขทะเบียนรถ : </span></strong>
                                                                        <span
                                                                            style={{
                                                                                color: car?.carPlateNumber === dataModalEdit?.carPlateNumber && car?.carProvince === dataModalEdit?.carProvince ? 'red' : 'black'
                                                                            }}
                                                                        >
                                                                            {car?.carPlateNumber} {car?.carProvince}
                                                                        </span>
                                                                    </div>
                                                                    {/* <div style={{ marginBottom: 0 }}>
                                                                    <strong><span>รายละเอียด : </span></strong>
                                                                    {car?.carBrand} {car?.carModel} {car?.carYear}
                                                                </div> */}
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>รายละเอียดรถ : </span></strong>
                                                                        <ul style={{ marginLeft: '2rem', listStyleType: 'none', paddingLeft: 0 }}>
                                                                            <li><strong>ยี่ห้อรถ: </strong>{car?.carBrand}</li>
                                                                            <li><strong>รุ่นรถ: </strong>{car?.carModel}</li>
                                                                            <li><strong>ปีรถ: </strong>{car?.carYear}</li>
                                                                            <li>
                                                                                <strong>เลขเครื่อง: </strong>
                                                                                <span style={{ color: car?.carEngineNumber === dataModalEdit.carEngineNumber ? 'red' : 'inherit' }}>
                                                                                    {car?.carEngineNumber ? car.carEngineNumber : "ยังไม่ได้เพิ่มเลขเครื่อง"}
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                <strong>เลขคัสซี: </strong>
                                                                                <span style={{ color: car?.carChassisNumber === dataModalEdit.carChassisNumber ? 'red' : 'inherit' }}>
                                                                                    {car?.carChassisNumber ? car.carChassisNumber : "ยังไม่ได้เพิ่มเลขคัสซี"}
                                                                                </span>
                                                                            </li>

                                                                        </ul>
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>เรท : </span></strong>
                                                                        {currencyFormatOne(car?.carPrice)}
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>สถานะ : </span></strong>
                                                                        <Tag color={ApprovalStatusColor(car?.carLoanDetails?.approvalStatus)}>{car?.carLoanDetails?.approvalStatusTable?.name}</Tag>
                                                                        <Tag color="green">{car?.carLoanDetails?.productLoanType}</Tag>
                                                                    </div>
                                                                    <Divider orientation="left"><b style={{ color: "red" }}>*การอนุมัติ!</b></Divider>
                                                                    {
                                                                        car?.carLoanDetails?.approvedBy ?
                                                                            <>
                                                                                <div style={{ marginBottom: 0 }}>
                                                                                    <strong><span>ผู้อนุมัติ : </span></strong>
                                                                                    {car?.carLoanDetails?.approvedBy}
                                                                                </div>
                                                                                <div style={{ marginBottom: 0 }}>
                                                                                    <strong><span>ยอดที่อนุมัติ : </span></strong>
                                                                                    {car?.carLoanDetails?.approvedLoanAmount}
                                                                                </div>
                                                                                <div style={{ marginBottom: 0 }}>
                                                                                    <strong><span>งวดที่อนุมัติ : </span></strong>
                                                                                    {car?.carLoanDetails?.approvedLoanTerm}
                                                                                </div>
                                                                            </>
                                                                            : <span>*ยังไม่อนุมัติ!</span>
                                                                    }
                                                                    <Divider />
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>ผู้เสนอเคส : </span></strong>
                                                                        {car?.carLoanDetails?.proposalBy}
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>สาขา : </span></strong>
                                                                        {car?.carLoanDetails?.branch}
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>วันที่เสนอ : </span></strong>
                                                                        {dayjs(car?.carInput).format("DD/MM/YYYY")}
                                                                    </div>
                                                                    <Divider orientation="left"><b style={{ color: "red" }}>*หมายเหตุ!</b></Divider>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        {
                                                                            car?.carLoanDetails?.notes?.map((item, index) => {
                                                                                return renderDupNote({ item, index })
                                                                            })
                                                                        }
                                                                    </Row>
                                                                </Panel>

                                                            ))}
                                                    </Collapse>
                                                </>
                                                :
                                                <>
                                                    <Row>
                                                        <Col span={12}>
                                                            <Form.Item label="ชื่อลูกค้า" style={{ marginBottom: 0 }}>
                                                                {dataModalEdit?.snam}{dataModalEdit?.firstName} {dataModalEdit?.lastName}
                                                            </Form.Item>
                                                            <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                                                {dataModalEdit?.carBrand} {dataModalEdit?.carYear} เลขโฉนด {dataModalEdit?.carPlateNumber}
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item label="พื้นที่ตารางวา" style={{ marginBottom: 0 }}>
                                                                {parseFloat(dataModalEdit?.carPrice)}
                                                            </Form.Item>
                                                            <Form.Item label="ราคาที่ดิน/ตารางวา" style={{ marginBottom: 0 }}>
                                                                {parseFloat(dataModalEdit?.carProvince)}
                                                            </Form.Item>
                                                            <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                                                <Tag color="gold">{dataModalEdit?.approvalStatusName}</Tag>
                                                                <Tag color="green">{dataModalEdit?.productLoanType}</Tag>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Divider orientation="left"><b style={{ color: "red" }}>*พบข้อมูลที่เคสเสนอมาแล้ว!</b></Divider>
                                                    <Collapse>
                                                        {
                                                            oldData?.map((land, index) => (
                                                                <Panel
                                                                    header={
                                                                        <>
                                                                            <strong>วันที่เสนอ :</strong> {dayjs(land.landInput).format("DD/MM/YYYY")} <strong>อยู่ในสถานะ :</strong> <Tag color={ApprovalStatusColor(land?.landLoanDetails?.approvalStatus)}>{land?.landLoanDetails?.approvalStatusTable?.name}</Tag>
                                                                        </>
                                                                    }
                                                                    key={index}
                                                                >
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>ชื่อลูกค้า : </span></strong>
                                                                        {land?.customer?.snam}{land?.customer?.firstname} {land?.customer?.lastname}
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>รายละเอียด : </span></strong>
                                                                        {land?.provinces} {land?.district} เลขโฉนด {land?.numberLand}
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>สถานะ : </span></strong>
                                                                        <Tag color={ApprovalStatusColor(land?.landLoanDetails?.approvalStatus)}>{land?.landLoanDetails?.approvalStatusTable?.name}</Tag>
                                                                        <Tag color="green">{land?.landLoanDetails?.productLoanLandType}</Tag>
                                                                    </div>
                                                                    <Divider orientation="left"><b style={{ color: "red" }}>*การอนุมัติ!</b></Divider>
                                                                    {
                                                                        land?.landLoanDetails?.approvedBy ?
                                                                            <>
                                                                                <div style={{ marginBottom: 0 }}>
                                                                                    <strong><span>ผู้อนุมัติ : </span></strong>
                                                                                    {land?.landLoanDetails?.approvedBy}
                                                                                </div>
                                                                                <div style={{ marginBottom: 0 }}>
                                                                                    <strong><span>ยอดที่อนุมัติ : </span></strong>
                                                                                    {land?.landLoanDetails?.approvedLoanAmount}
                                                                                </div>
                                                                                <div style={{ marginBottom: 0 }}>
                                                                                    <strong><span>งวดที่อนุมัติ : </span></strong>
                                                                                    {land?.landLoanDetails?.approvedLoanTerm}
                                                                                </div>
                                                                            </>
                                                                            : <span>*ยังไม่อนุมัติ!</span>
                                                                    }
                                                                    <Divider />
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>ผู้เสนอเคส : </span></strong>
                                                                        {land?.landLoanDetails?.proposalBy}
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>สาขา : </span></strong>
                                                                        {land?.landLoanDetails?.branch}
                                                                    </div>
                                                                    <div style={{ marginBottom: 0 }}>
                                                                        <strong><span>วันที่เสนอ : </span></strong>
                                                                        {dayjs(land?.carInput).format("DD/MM/YYYY")}
                                                                    </div>
                                                                    <Divider orientation="left"><b style={{ color: "red" }}>*หมายเหตุ!</b></Divider>
                                                                    <Row gutter={32} justify={'center'}>
                                                                        {
                                                                            land?.landLoanDetails?.notes?.map((item, index) => {
                                                                                return renderDupNote({ item, index })
                                                                            })
                                                                        }
                                                                    </Row>
                                                                </Panel>

                                                            ))}
                                                    </Collapse>
                                                </>
                                        }
                                    </>
                                    :
                                    <>
                                        <Form.Item label="ชื่อลูกค้า" style={{ marginBottom: 0 }}>
                                            {dataModalEdit?.snam}{dataModalEdit?.firstName} {dataModalEdit?.lastName}
                                        </Form.Item>
                                        {dataModalEdit?.car === "car" ?
                                            <>
                                                <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                                    {dataModalEdit?.carBrand} {dataModalEdit?.carModel} {dataModalEdit?.carYear}
                                                </Form.Item>
                                                <Form.Item label="เลขทะเบียนรถ" style={{ marginBottom: 0 }}>
                                                    {dataModalEdit?.carPlateNumber} {dataModalEdit?.carProvince}
                                                </Form.Item>
                                                <Form.Item label="เรท" style={{ marginBottom: 0 }}>
                                                    {currencyFormatOne(dataModalEdit?.carPrice)}
                                                </Form.Item>
                                                <Form.Item label="เลขเครื่อง" style={{ marginBottom: 0 }}>
                                                    {dataModalEdit?.carEngineNumber ? (
                                                        dataModalEdit.carEngineNumber
                                                    ) : (
                                                        "ยังไม่ได้เพิ่มเลขเครื่อง"
                                                    )}
                                                </Form.Item>
                                                <Form.Item label="เลขคัสซี" style={{ marginBottom: 0 }}>
                                                    {dataModalEdit?.carChassisNumber ? (
                                                        dataModalEdit.carChassisNumber
                                                    ) : (
                                                        "ยังไม่ได้เพิ่มเลขคัสซี"
                                                    )}
                                                </Form.Item>

                                            </>
                                            :
                                            <>
                                                <Form.Item label="รายละเอียด" style={{ marginBottom: 0 }}>
                                                    {dataModalEdit?.carBrand} {dataModalEdit?.carYear} เลขโฉนด {dataModalEdit?.carPlateNumber}
                                                </Form.Item>
                                                <Form.Item label="พื้นที่ตารางวา" style={{ marginBottom: 0 }}>
                                                    {parseFloat(dataModalEdit?.carPrice)}
                                                </Form.Item>
                                                <Form.Item label="ราคาที่ดิน/ตารางวา" style={{ marginBottom: 0 }}>
                                                    {parseFloat(dataModalEdit?.carProvince)}
                                                </Form.Item>
                                            </>
                                        }
                                        <Form.Item label="สถานะ" style={{ marginBottom: 0 }}>
                                            <Tag color="gold">{dataModalEdit?.approvalStatusName}</Tag>
                                            <Tag color="green">{dataModalEdit?.productLoanType}</Tag>
                                        </Form.Item>
                                    </>
                            }
                            {
                                checkOldDB2 ?
                                    <>
                                        {
                                            oldDataDB2?.length > 0 ?
                                                <>
                                                    <Divider orientation="left"><b style={{ color: "red" }}>*พบข้อมูลที่มีในสัญญาแล้ว!</b></Divider>
                                                    <Collapse>
                                                        {
                                                            oldDataDB2?.map((car, index) => (
                                                                <>
                                                                    <Panel
                                                                        header={
                                                                            <>
                                                                                <strong>รายละเอียด ({car?.CONTNO})</strong>
                                                                                {/* <Tag color={ApprovalStatusColor(car?.carLoanDetails?.approvalStatus)}>{car?.carLoanDetails?.approvalStatusTable?.name}</Tag> */}
                                                                                <span style={{ color: 'red' }}>
                                                                                    <strong>
                                                                                        {car?.STRNO === dataModalEdit?.carChassisNumber && (
                                                                                            <span style={{ margin: '16px' }}>เลขคัสซีตรงกัน!</span>
                                                                                        )}
                                                                                        {car?.ENGNO === dataModalEdit?.carEngineNumber && (
                                                                                            <span style={{ margin: '16px' }}>เลขเครื่องตรงกัน!</span>
                                                                                        )}
                                                                                        {car?.REGNO === dataModalEdit?.carPlateNumber && car?.DORECV === dataModalEdit?.carProvince && (
                                                                                            <span style={{ margin: '16px' }}>เลขทะเบียนตรงกัน!</span>
                                                                                        )}
                                                                                    </strong>
                                                                                </span>
                                                                            </>
                                                                        }
                                                                        key={index}
                                                                    >
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <strong><span>เลขสัญญา : </span></strong>
                                                                            {car?.CONTNO}
                                                                        </div>
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <strong><span>ชื่อลูกค้า : </span></strong>
                                                                            {car?.SNAME}{car?.NAME1} {car?.NAME2}
                                                                        </div>
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <strong><span>เลขทะเบียนรถ : </span></strong>
                                                                            {/* {car?.REGNO} {car?.DORECV} */}
                                                                            <span
                                                                                style={{
                                                                                    color: car?.REGNO === dataModalEdit?.carPlateNumber && car?.DORECV === dataModalEdit?.carProvince ? 'red' : 'black'
                                                                                }}
                                                                            >
                                                                                {car?.REGNO} {car?.DORECV}
                                                                            </span>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <strong><span>รายละเอียดรถ : </span></strong>
                                                                            <ul style={{ marginLeft: '2rem', listStyleType: 'none', paddingLeft: 0 }}>
                                                                                <li><strong>ยี่ห้อรถ: </strong>{car?.TYPE}</li>
                                                                                <li><strong>รุ่นรถ: </strong>{car?.MODEL}</li>
                                                                                <li><strong>ปีรถ: </strong>{car?.MANUYR}</li>
                                                                                <li>
                                                                                    <strong>เลขเครื่อง: </strong>
                                                                                    <span style={{ color: car?.ENGNO === dataModalEdit.carEngineNumber ? 'red' : 'inherit' }}>
                                                                                        {car?.ENGNO ? car.ENGNO : "ยังไม่ได้เพิ่มเลขเครื่อง"}
                                                                                    </span>
                                                                                </li>
                                                                                <li>
                                                                                    <strong>เลขคัสซี: </strong>
                                                                                    <span style={{ color: car?.STRNO === dataModalEdit.carChassisNumber ? 'red' : 'inherit' }}>
                                                                                        {car?.STRNO ? car.STRNO : "ยังไม่ได้เพิ่มเลขคัสซี"}
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <strong><span>วันที่ทำสัญญา : </span></strong>
                                                                            {car?.SDATE}
                                                                        </div>

                                                                        <Divider />
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <strong><span>ผู้เสนอเคส : </span></strong>
                                                                            {car?.SALCOD}
                                                                        </div>
                                                                        <div style={{ marginBottom: 0 }}>
                                                                            <strong><span>สาขา : </span></strong>
                                                                            {car?.LOCAT}
                                                                        </div>

                                                                    </Panel>
                                                                </>

                                                            ))}
                                                    </Collapse>

                                                </>
                                                : null
                                        }
                                    </>
                                    : null
                            }

                            {/* Show Image */}
                            <Divider style={{ margin: 5 }} />
                            {dataFromTable?.car === "car" ?
                                <>
                                    <Row justify={'left'}><b><h3><u>หน้าคู่มือ </u></h3></b></Row>
                                    <Row justify={'center'}>
                                        <aside justify={'center'}>
                                            <div className='gutter-row' span={12}>
                                                <b>
                                                    {imageBlobzz.length > 0 ?
                                                        <>
                                                            {imageBlobzz?.map((imageData, index) => {
                                                                if (imageData.type === 2) {
                                                                    return (
                                                                        <>
                                                                            <Row justify={'center'}>
                                                                                <Image
                                                                                    width={150}
                                                                                    src={`${getImagess}/${imageData.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index}`} />
                                                                            </Row>
                                                                        </>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </>
                                                        : null
                                                    }
                                                </b>
                                            </div>
                                        </aside>
                                    </Row>
                                    <Divider style={{ margin: 5 }} />
                                    <Row justify={'left'}><b><h3><u>ใบประเมิน / รูปรถ </u></h3></b></Row>
                                    <Row justify={'center'}>
                                        <aside>
                                            <b>
                                                {imageBlobzz.length > 0 ?
                                                    <>
                                                        {imageBlobzz?.map((imageData, index) => {
                                                            if (imageData.type === 1) {
                                                                return (
                                                                    <>
                                                                        <Row justify={'center'}>
                                                                            <Image
                                                                                width={150}
                                                                                src={`${getImagess}/${imageData.pathImage}?token=${token}`}
                                                                                alt={`รูปภาพ ${index}`} />
                                                                        </Row>
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </>
                                                    : null
                                                }

                                            </b>
                                        </aside>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row justify={'left'}><b><h3><u>ใบประเมิน/รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน </u></h3></b></Row>
                                    <Row justify={'center'}>
                                        <aside justify={'center'}>
                                            <div className='gutter-row' span={12}>
                                                <b>
                                                    {imageBlobzz.length > 0 ?
                                                        <>
                                                            {imageBlobzz?.map((imageData, index) => {
                                                                if (imageData.type === 1) {
                                                                    return (
                                                                        <>
                                                                            <Row justify={'center'}>
                                                                                <Image
                                                                                    width={150}
                                                                                    src={`${getImagess}/${imageData.pathImage}?token=${token}`}
                                                                                    alt={`รูปภาพ ${index}`} />
                                                                            </Row>
                                                                        </>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </>
                                                        : null
                                                    }
                                                </b>
                                            </div>
                                        </aside>
                                    </Row>
                                    <Divider style={{ margin: 5 }} />
                                    <Row justify={'left'}><b><h3><u>รูปโฉนด/หน้า-หลัง </u></h3></b></Row>
                                    <Row justify={'center'}>
                                        <aside>
                                            <b>
                                                {imageBlobzz.length > 0 ?
                                                    <>
                                                        {imageBlobzz?.map((imageData, index) => {
                                                            if (imageData.type === 3) {
                                                                return (
                                                                    <>
                                                                        <Row justify={'center'}>
                                                                            <Image
                                                                                width={150}
                                                                                src={`${getImagess}/${imageData.pathImage}?token=${token}`}
                                                                                alt={`รูปภาพ ${index}`} />
                                                                        </Row>
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </>
                                                    : null}

                                            </b>
                                        </aside>
                                    </Row>
                                    <Row justify={'left'}><b><h3><u>ใบแบ่งใบต่อ </u></h3></b></Row>
                                    <Row justify={'center'}>
                                        <aside>
                                            <b>
                                                {imageBlobzz.length > 0 ?
                                                    <>
                                                        {imageBlobzz?.map((imageData, index) => {
                                                            if (imageData.type === 10) {
                                                                return (
                                                                    <>
                                                                        <Row justify={'center'}>
                                                                            <Image
                                                                                width={150}
                                                                                src={`${getImagess}/${imageData.pathImage}?token=${token}`}
                                                                                alt={`รูปภาพ ${index}`} />
                                                                        </Row>
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </>
                                                    : null}
                                            </b>
                                        </aside>
                                    </Row>
                                    <Row justify={'left'}><b><h3><u>ระวาง </u></h3></b></Row>
                                    <Row justify={'center'}>
                                        <aside>
                                            <b>
                                                {imageBlobzz.length > 0 ?
                                                    <>
                                                        {imageBlobzz?.map((imageData, index) => {
                                                            if (imageData.type === 11) {
                                                                return (
                                                                    <>
                                                                        <Row justify={'center'}>
                                                                            <Image
                                                                                width={150}
                                                                                src={`${getImagess}/${imageData.pathImage}?token=${token}`}
                                                                                alt={`รูปภาพ ${index}`} />
                                                                        </Row>
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </>
                                                    : null}
                                            </b>
                                        </aside>
                                    </Row>
                                </>
                            }
                            {/* Show Image END */}
                        </Form>
                    </Card>
                </Spin>
            </Modal>
        </div >
    )
}

export default ModalAccept