import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Space } from 'antd'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../redux/Customer';
import { addCar } from '../../../redux/Car';
import { addAddress } from '../../../redux/Address';
import { addPhone } from '../../../redux/Phone';
import { addGuarantor } from '../../../redux/Guarantor';

// import Customer from './AddToApprove/Customer';
// import Address from './AddToApprove/Address';
// import Contact from './AddToApprove/Contact';
// import Career from './AddToApprove/Career';
import Conclusion from './AddToApprove/Conclusion';
import { findcarpg, findlandpg } from '../../file_mid/all_api';
// import Product from './AddToApprove/Product';

function ModalPreApprove({ open, close, dataFromTable, SendBack }) {

    const customers = useSelector(state => state.customers)
    const guarantor = useSelector(state => state.guarantor)
    const cars = useSelector(state => state.cars)

    // const [dataPost] = useState(customers.data); //มาจากรีดัค
    const [dataMix] = useState({ carId: cars.data, customerId: customers.data }); //มาจากรีดัค
    // const [api, contextHolder] = notification.useNotification();
    const [getData, setGetData] = useState();
    const [count, setCount] = useState(6)
    const [resetPage, setResetPage] = useState({})
    const [memo, setMemo] = useState();
    const [loanRe, setLoanRe] = useState();
    const [isu, setIsu] = useState();
    const dispatch = useDispatch()

    useEffect(() => {
        // loadData()
        if (guarantor?.data?.typeLoan === "car") {
            loadDataCar()
        } else if (guarantor?.data?.typeLoan === "land") {
            loadDataLand()
        }
    }, [customers.data, resetPage])

    const loadDataCar = async () => {
        await axios.post(findcarpg, dataMix)
            .then(res => {
                if (res.status === 200) {
                    console.log("loadDataCar", res.data)
                    setGetData(res.data)
                    setMemo(res.data.cars.carMemo)
                    setLoanRe(res.data.cars.carLoanDetailsRe)
                    setIsu(res.data.cars.ISSUNO)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }
    const loadDataLand = async () => {
        await axios.post(findlandpg, dataMix)
            .then(res => {
                if (res.status === 200) {
                    console.log("loadDataLand", res.data)
                    setGetData(res.data)
                    setLoanRe(res.data.lands.landLoanDetailsRe)
                    setIsu(res.data.lands.ISSUNO)
                }
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const Onclose = () => {
        close(false);
        dispatch(addCustomer())
        dispatch(addCar())
        dispatch(addAddress())
        dispatch(addPhone())
        dispatch(addGuarantor())
    };

    return (
        <div>
            <Modal title="เพิ่ม/แก้ไขข้อมูล" open={open} onCancel={Onclose} width={1100} footer={null}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: 'flex',
                    }}>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            {/* <Radio.Group value={count} onChange={(e) => setCount(e.target.value)}>
                                <Radio.Button value={1}>ข้อมูลส่วนตัว</Radio.Button>
                                <Radio.Button value={2}>รายละเอียดที่อยู่</Radio.Button>
                                <Radio.Button value={3}>รายละเอียดสินค้า</Radio.Button>
                                <Radio.Button value={4}>ช่องทางการติดต่อ</Radio.Button>
                                <Radio.Button value={5}>รายได้และอาชีพ</Radio.Button>
                                <Radio.Button value={6}>รายละเอียดและผู้ค้ำประกัน</Radio.Button>
                            </Radio.Group> */}
                        </Col>
                    </Row>
                    {/* {count === 1 && (<Customer getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} />)}
                    {count === 2 && (<Address getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} />)}
                    {count === 3 && (<Product getData={getData} close={Onclose} page={count} changepage={setCount} sendback={setResetPage} deleteData={NotiDelete} editData={NotiSuccess} addImg={NotiSuccessAdd} DeleteError={DeleteError} />)}
                    {count === 4 && (<Contact dataMix={dataMix} getData={getData} close={Onclose} page={count} sendback={setResetPage} changepage={setCount} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} />)}
                    {count === 5 && (<Career close={Onclose} page={count} changepage={setCount} sendback={setResetPage} />)} */}
                    {count === 6 && (<Conclusion getData1={getData} close={Onclose} sendback={setResetPage} SendBack={SendBack} memo={memo} loanRe={loanRe} isu={isu} dataFromTable={dataFromTable} />)}
                    {/* {count === 6 && (<Conclusion getData1={getData} close={Onclose} page={count} changepage={setCount} notification={NotiSuccess} notification2={NotiError} notification3={NotiSuccessAdd} notification4={NotiErrorAdd} notification5={NotiDelete} NotiNotFond={NotiNotFond} addrER={CheckAddressER} sendback={setResetPage} />)} */}
                </Space>
            </Modal>
            {/* {contextHolder} */}
        </div>
    )
}

export default ModalPreApprove