import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, InputNumber, Tag, DatePicker, Checkbox, Select, Modal, TextArea } from 'antd'
// import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import dayjs from "dayjs";
import ExcelJS from 'exceljs';
import { PrinterOutlined } from '@ant-design/icons';
import { DataExcelPay } from './excel/data_excel';
import { mainDataExcel, headersThai, headersColumsName, mainDataExcelDetail, headersThaiDetail, headersColumsNameDetail } from './excel/cl';
import { columns, columnsDetail, columnsHDPAYMENT, columnsAROTHR } from './table/cm';
import { currencyFormatOne, msSale, msError, msDue, msNil, msLimit, msCreateDue, msDueNil, msConnt, msPAYCODE, msOK, msPrint, msSaleFollow, msData, msCancel, msClose, msCal, currencyFormat, msPayGRADE, msErrorInsert, msOKMemo1, msErrorInst } from "../file_mid/allFormat";
import { calPay, calPayAROTHR, calPayGRADE, calPayHD } from "./cal/Cal";
import Payment from "../file_mid/reportPayment/Payment3New";
import { loadPrintDataPayment, loadDataPayment, loadDataFindPAYTYP, funcInsertMemo1, loadPrintDataPaymentMulti } from "./load_data_print/data_pay";
import { payamt, confirmPay, loadPayamt } from "../file_mid/all_api";
import { optionsPayType, optionsSearchPay } from "../file_mid/all_options";
// import PaymentMulti from "../receipt_multiple/ReportPaymentAuto/PaymentAuto";

export default function Main() {
  const { TextArea } = Input;
  const { confirm } = Modal
  const token = localStorage.getItem('token');
  const currentToken = localStorage.getItem("currentToken");
  const currentDateTime = dayjs()
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [formKey, setFormKey] = useState(0);
  const [formKey2, setFormKey2] = useState(0);
  const [formKey3, setFormKey3] = useState(0);
  const componentPDF = useRef();
  const generatePDF = useReactToPrint({ content: () => componentPDF.current, documentTitle: "ใบรับเงิน", }); // บิลปกติ
  const componentPDF2 = useRef();
  const generatePDF2 = useReactToPrint({ content: () => componentPDF2.current, documentTitle: "ใบรับเงิน", }); // บิลปกติ + บิลปิด
  const [loading, setLoading] = useState(false);
  const [checkGuas, setCheckGuas] = useState("")
  const [dataRec, setDataRec] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [showTableMulti, setShowTableMulti] = useState(false);
  const [isClicked, setIsClicked] = useState(1);
  const [isClickedTy, setIsClickedTy] = useState(1); // เลือกค้นหาจาก
  const [checkAgain, setCheckAgain] = useState(true);
  const [checkContnoNew, setCheckContnoNew] = useState(false);
  const [checkDataInsert, setCheckDataInsert] = useState(false);
  const [checkButton, setCheckButton] = useState(1);
  const [checkButtonPay, setCheckButtonPay] = useState(true);
  const [checkCt, setCheckCt] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [dataCode, setDataCode] = useState({ codeQ: "", codeCCB: "" })
  const [dataSend, setDataSend] = useState({
    textData: "",
    textData2: "",
    CONTNO: "",
    money: 0,
    sale: 0,
    saleFollow: 0,
    date: currentDateTime,
    payType: "จ่ายเข้าตารางดิว",
    PAYCODE: "01",
    selectPay: 1,
    MEMO1: "",
  });
  const [getPAYCODE, setGetPAYCODE] = useState([]);
  const [findPAYTYP, setFindPAYTYP] = useState([]);
  const [dataAll, setDataAll] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [dataHDPAYMENT, setDataHDPAYMENT] = useState([]);
  const [dataAROTHR, setDataAROTHR] = useState([]);
  const [dataTableDetail, setDataTableDetail] = useState([]);
  const [dataPay, setDataPay] = useState([]);
  const [calData, setCalData] = useState({
    ALLPAYAMT: 0,
    ALLPAYFOLLOW: 0,
    ALLPAYINTEFF: 0,
    ALLPAYTON: 0,
    ALLCAL: 0,
    text: "",
  });
  const [sum, setSum] = useState({
    sumAmt: 0,
    sumFollow: 0,
    sumAll: 0,
    sumMoney: 0,
    HDPAY: 0, // เข้ารับฝาก
    amt: 0,
    nopay: 0,
    GRDCOD: "",
    AROTHR: 0, // โชว์ลูกหนี้อื่นๆ มาจากหลังบ้าน
    HD: 0, // โชว์รับฝาก มาจากหลังบ้าน
    payGRADE: 0,
    flagTMPTELDEBT: null,
    flagCutTon: false,
  });
  useEffect(() => {
    loadData()
  }, [])
  const loadData = async () => {
    setLoading(true)
    try {
      const result = await loadDataPayment();
      setGetPAYCODE(result)
    } catch (error) {
      console.error("Error in loadData: ", error);
      setGetPAYCODE([])
    } finally {
      setLoading(false);
    }
  }
  const buttonAmt = async (value) => {
    setCheckButton(value)
    setIsClicked(value)
  }
  const handleSelectCONTNO = async (value) => {
    console.log(value)
    if (value) {
      setLoading(true);
      setTimeout(() => {
        setDataSend({ ...dataSend, CONTNO: value })
        setCheckDataInsert(false)
        setCheckContnoNew(false)
        setLoading(false);
      }, 1000);
    }
  }
  const handleCONTNO = (e, number) => {
    if (number === 31 || number === 32) {
      if (number === 31) {
        setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value })
      } else {
        setDataSend({ ...dataSend, CONTNO: "", textData2: e.target.value })
      }
      setFindPAYTYP([])
      form.setFieldsValue({ CONTNO: "", })
    } else {
      if (isClickedTy === 1) {
        console.log("e.target.value", e.target.value)
        setDataSend({ ...dataSend, CONTNO: e.target.value, textData: "" })
      } else {
        console.log("e.target.value", e.target.value)
        // setLoading(true);
        // setTimeout(() => {
        setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value })
        setFindPAYTYP([])
        //   setLoading(false);
        // }, 1000);
        form.setFieldsValue({ CONTNO: "", })

      }
    }
    setCheckDataInsert(false)
    setCheckContnoNew(false)
  }
  const handleMEMO1 = (value) => {
    // console.log("value MEMO1", value)
    setDataSend((prevData) => ({ ...prevData, MEMO1: value }));
  }
  const changeSelectPay = (value) => {
    if (dataSend.payType === "จ่ายเข้าตารางดิว") {
      console.log("value =:", value)
      setDataSend({ ...dataSend, selectPay: value, money: 0, sale: 0, saleFollow: 0 })
      form2.setFieldsValue({ money: "", sale: "", saleFollow: "", });
    } else {
      setDataSend({ ...dataSend, selectPay: 1, money: 0, sale: 0, saleFollow: 0 })
      form2.setFieldsValue({ selectPay: "ปกติ" });
    }
  }
  const changePAYCODE = (value) => {
    console.log("value =:", value)
    setDataSend({ ...dataSend, PAYCODE: value })
  }
  const funcMoney = (value) => {
    console.log("value =:", value)
    setCheckDataInsert(false)
    if (value) {
      setDataSend({ ...dataSend, money: parseFloat(value) })
    } else {
      setDataSend({ ...dataSend, money: 0 })
    }
  }
  const funcSale = (value) => {
    setCheckDataInsert(false)
    console.log("value", value)
    console.log("(dataPay?.mixDok)", dataPay?.mixDok)
    if (dataSend.selectPay === 1) { // ปกติ
      if (value) {
        if (value <= dataPay?.mixDok && dataSend.payType === "จ่ายเข้าตารางดิว") {
          // console.log("if", value)
          setDataSend({ ...dataSend, sale: parseFloat(value) })
        } else {
          // console.log("else", value)
          setDataSend({ ...dataSend, sale: 0 })
          form2.setFieldsValue({ sale: "", });
          msSale(api, "top")
        }
      } else {
        setDataSend({ ...dataSend, sale: 0 })
      }
    } else { // พิเศษ
      if (value) {
        setDataSend({ ...dataSend, sale: parseFloat(value) })
      } else {
        setDataSend({ ...dataSend, sale: 0 })
      }
    }
  }
  const funcSaleFollow = (value) => {
    setCheckDataInsert(false)
    console.log("vvv", value)
    console.log("dataPay?.followCCB", dataPay?.followCCB)
    if (value) {
      if (value <= dataPay?.followCCB && dataSend.payType === "จ่ายเข้าตารางดิว") {
        setDataSend({ ...dataSend, saleFollow: parseFloat(value) })
      } else {
        setDataSend({ ...dataSend, saleFollow: 0 })
        form2.setFieldsValue({ saleFollow: "", });
        msSaleFollow(api, "top")
      }
    } else {
      setDataSend({ ...dataSend, saleFollow: 0 })
    }
  }
  const onChangecheckGuas = (value) => {
    console.log(value)
    setCheckGuas(value);
    if (value && value === "จ่ายเข้าตารางดิว") {
      setDataSend({ ...dataSend, payType: value, money: 0, sale: 0, saleFollow: 0 })
      form2.setFieldsValue({ money: "", sale: "", saleFollow: "", });
    } else if (value && value === "จ่ายแบบไม่เข้าตารางดิว") {
      setDataSend({ ...dataSend, payType: value, money: 0, sale: 0, saleFollow: 0, selectPay: 1 })
      form2.setFieldsValue({ money: "", sale: "", saleFollow: "", selectPay: "ปกติ" });
    } else {
      console.log("ไม่เข้า")
    }
  };

  const callHandleSubmitPay = async () => {
    let sumMSF = 0;
    let payGRADE = sum?.payGRADE;
    console.log("1 ปกติ / 2 พิเศษ", dataSend.selectPay)
    setFormKey2(prevKey => prevKey + 1);
    if (checkContnoNew === true) {
      if (sum?.flagTMPTELDEBT && sum?.flagTMPTELDEBT === true) {
        sumMSF = (dataSend.money + dataSend.sale + dataSend.saleFollow)
        if (sum?.GRDCOD === "A" || sum?.GRDCOD === "B" || sum?.GRDCOD === "C") {
          payGRADE = payGRADE - 100
        }
        console.log(sumMSF, sum?.GRDCOD, payGRADE)
        if (sumMSF >= payGRADE) {
          handleSubmitPay()
        } else {
          if (dataSend.selectPay === 2) {
            console.log("ss")
            handleSubmitPay()
          } else {
            console.log("xx")
            msPayGRADE(api, "top")
          }
        }
      } else {
        handleSubmitPay()
      }
    } else {
      msCal(api, "top")
    }
  }
  const handleSubmitMemo = async () => {
    setFormKey3(prevKey => prevKey + 1);
    console.log("memo")
    setLoading(true)
    try {
      const result = await funcInsertMemo1(dataSend?.CONTNO, dataSend?.MEMO1);
      if (result === 200) {
        msOKMemo1(api, "top")
      } else {
        msErrorInst(api, "top")
      }
    } catch (error) {
      console.error("Error in funcInsertMemo1: ", error);
      msErrorInst(api, "top")
    } finally {
      setLoading(false);
    }
  }

  const handleSubmitPay = async () => {
    // if (dataSend.CONTNO !== "" && dataSend.money > 0 && dataSend.payType !== "" && (dataSend.money + dataSend.sale + dataSend.saleFollow) >= sum?.amt) {
    console.log(dataSend.CONTNO, dataSend.money, dataSend.sale, dataSend.saleFollow, dataSend.payType, dataSend.selectPay)
    if (dataSend.CONTNO !== "" && (dataSend.money + dataSend.sale + dataSend.saleFollow) > 0 && dataSend.payType !== "" && dataSend.selectPay > 0) {
      setCheckButtonPay(false)
      let mixData
      // if (dataSend?.sale > 0) {
      mixData = {
        CONTNO: dataSend.CONTNO, money: dataSend.money,
        sale: dataSend.sale ? dataSend.sale : 0, saleFollow: dataSend.saleFollow ? dataSend.saleFollow : 0,
        date: dataSend.date, payType: dataSend.payType, selectPay: dataSend.selectPay,
      }
      // } else {
      //   mixData = { CONTNO: dataSend.CONTNO, money: dataSend.money, sale: 0, date: dataSend.date, payType: dataSend.payType }
      // }
      console.log("mixData", mixData)
      // console.log("sumMoney", mixData.money + mixData.sale + mixData.saleFollow)
      // const tk = JSON.parse(token)
      // const headers = {
      //   "Authorization": `Bearer ${tk}`,
      //   "Menu": JSON.stringify("5")
      // }
      setLoading(true)
      // await axios.post("http://localhost:8070/api-db2/payamt", mixData)
      await axios.post(payamt, mixData)
        .then(async (res) => {
          if (res.status === 200) {
            if (res.data) {
              setDataAll(res.data)
              setCheckDataInsert(true)
              console.log("res.data payamt", res.data)
              // await calPay(res.data, "")
              const result = await calPay(res.data, "");
              console.log("result calPay", result)
              setSum({ ...sum, sumMoney: res.data.money, HDPAY: res.data.HDPAY })
              // sumMoney = mixData.money + mixData.sale + mixData.saleFollow
              setCalData(result)
              setDataTable(res.data.result)
              if (res.data?.detailPay) {
                setDataTableDetail(res.data.detailPay)
              } else {
                setDataTableDetail([])
              }
            } else {
              setDataAll(null)
              setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "ไม่สามารถปิดบช.ได้ กรุณาติดต่อไอที" })
              setDataTable([])
              setDataTableDetail([])
              msClose(api, "top")
            }
          } else if (res.status === 202) {
            console.log("ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน", res.data)
            // await calPay(res.data, "ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน")
            const result = await calPay(res.data, "ไม่ได้จ่ายล่วงหน้า คิดดอกเป็นรายวัน");
            setDataAll(res.data)
            setCheckDataInsert(true)
            setCalData(result)
            setDataTable([])
            setDataTableDetail([])
            // setDataPay([])
          } else if (res.status === 203) {
            console.log("จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ", res.data)
            // setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: res.data.ccb, text: "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" })
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" })
            setDataTable([])
            setDataTableDetail([])
            setDataAll(null)
            // setDataPay([])
            msLimit(api, "top")
          } else if (res.status === 204) {
            console.log("กรอกยอดเงินไม่ถูกต้อง")
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "กรอกยอดเงินไม่ถูกต้อง" })
            setDataTable([])
            setDataTableDetail([])
            setDataAll(null)
            // setDataPay([])
            msNil(api, "top")
          } else if (res.status === 205) {
            console.log("เงินน้อยกว่าดอกเบี้ยสร้างดิว")
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "เงินน้อยกว่าดอกเบี้ยสร้างดิว" })
            setDataTable([])
            setDataTableDetail([])
            setDataAll(null)
            msCreateDue(api, "top")
          } else if (res.status === 208) {
            console.log("ไม่พบดิวล่าสุด")
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "ไม่พบดิวล่าสุด" })
            setDataTable([])
            setDataTableDetail([])
            setDataAll(null)
            msDueNil(api, "top")
          } else {
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
            setDataTable([])
            setDataTableDetail([])
            setDataAll(null)
            // setDataPay([])
            msNil(api, "top")
          }
          setTimeout(() => {
            setDataCode({ codeQ: "", codeCCB: "" })
            form3.setFieldsValue({ codeQ: "", codeCCB: "" });
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log("err", err)
          setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          setDataTable([])
          setDataTableDetail([])
          // setDataPay([])
          msError(api, "top")
          setLoading(false)
        })
    } else {
      msNil(api, "top")
    }
  }

  const handleSubmit = async () => { // ค้นหา
    console.log(dataSend?.CONTNO, isClickedTy, dataSend.textData)
    setFormKey(prevKey => prevKey + 1);
    if (dataSend?.CONTNO !== "") {
      setCheckContnoNew(true)
      setCheckCt(false);
      setCheckDataInsert(false);
      setCheckButtonPay(true);
      setCheckButton(1);
      setIsClicked(1);
      setDataRec(null);
      setShowTable(false);
      setShowTableMulti(false);
      setDataAll(null);
      let sumAmt = 0;
      let sumFollow = 0;
      let sumAll = 0;
      let resultCalPayAROTHR = 0;
      let resultCalPayGRADE = 0;
      let resultCalPayHD = 0;
      setLoading(true)
      // await axios.post("http://localhost:8070/api-db2/load-payamt", dataSend)
      await axios.post(loadPayamt, dataSend)
        .then(async (res) => {
          console.log("res", res)
          if (res.data?.AROTHR) {
            resultCalPayAROTHR = await calPayAROTHR(res.data.AROTHR);
            console.log("resultCalPayAROTHR", resultCalPayAROTHR)
          }
          if (res.data?.HDPAYMENT) {
            resultCalPayHD = await calPayHD(res.data?.HDPAYMENT[0]?.TOTAMT);
            console.log("resultCalPayHD", resultCalPayHD)
          }
          if (res.status === 200) {
            onChangecheckGuas("จ่ายเข้าตารางดิว")
            console.log("res.data load-payamt", res.data)
            setDataPay(res.data)
            if (res.data?.result && res.data?.ccb !== 0) {
              setDataTable(res.data.result)
              if (res.data?.flagTMPTELDEBT?.Flag === true) {
                resultCalPayGRADE = await calPayGRADE(res.data.result[0]?.GRDCOD, res.data.result[0]?.DUEAMT, res.data?.flagTMPTELDEBT.GRDCAL);
                console.log("resultCalPayGRADE", resultCalPayGRADE)
              }
              if (res.data.flagDue === false) {
                await res.data.result.forEach((item) => {
                  sumAmt += item.DUEAMT - item.PAYAMT;
                  sumFollow += item.FOLLOWAMT - item.PAYFOLLOW;
                });
                sumAll = sumAmt + sumFollow
                setSum({
                  sumAmt: sumAmt, sumFollow: sumFollow, sumAll: sumAll + resultCalPayAROTHR, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
                  HDPAY: 0, amt: res.data.result[0]?.DUEAMT, nopay: res.data.result?.length, GRDCOD: res.data.result[0]?.GRDCOD,
                  AROTHR: resultCalPayAROTHR, HD: resultCalPayHD, payGRADE: resultCalPayGRADE, flagTMPTELDEBT: res.data?.flagTMPTELDEBT?.Flag,
                  flagCutTon: res.data.flagCutTon,
                  // tonKongCCB: 0, dokCCB: 0, followCCB: 0,
                })
              } else {
                setSum({
                  sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
                  HDPAY: 0, amt: res.data.result[0]?.DUEAMT, nopay: 0, GRDCOD: res.data.result[0]?.GRDCOD,
                  AROTHR: resultCalPayAROTHR, HD: resultCalPayHD, payGRADE: resultCalPayGRADE, flagTMPTELDEBT: res.data?.flagTMPTELDEBT?.Flag,
                  flagCutTon: res.data.flagCutTon,
                })
                if (res.data.ccb <= 0) {
                  setCheckCt(true)
                }
              }
              if (res.data?.detailPay) {
                setDataTableDetail(res.data?.detailPay)
              } else {
                setDataTableDetail([])
              }
              console.log("sumAmt", sumAmt)
              console.log("sumFollow", sumFollow)
            } else {
              setCheckCt(true)
              setSum({
                sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
                HDPAY: 0, amt: 0, nopay: 0, GRDCOD: "", AROTHR: 0, HD: 0, payGRADE: 0, flagTMPTELDEBT: null,
                flagCutTon: res.data.flagCutTon,
              })
              setDataTable([])
              setDataTableDetail([])
            }
          } else if (res.status === 201) {
            console.log("res.data", res.data)
            setCheckCt(true)
            onChangecheckGuas("")
            setDataTable([])
            setDataTableDetail([])
            setDataPay([])
            setSum({
              sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
              HDPAY: 0, amt: 0, nopay: 0, GRDCOD: "", AROTHR: 0, HD: 0, payGRADE: 0, flagTMPTELDEBT: null,
              flagCutTon: false,
            })
            msDue(api, "top")
          } else {
            setCheckCt(true)
            onChangecheckGuas("")
            setDataTable([])
            setDataTableDetail([])
            setDataPay([])
            setSum({
              sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
              HDPAY: 0, amt: 0, nopay: 0, GRDCOD: "", AROTHR: 0, HD: 0, payGRADE: 0,
              flagCutTon: false,
            })
            msConnt(api, "top")
          }
          setTimeout(() => {
            setCheckAgain(true);
            setDataHDPAYMENT(res.data?.HDPAYMENT)
            setDataAROTHR(res.data?.AROTHR)
            setDataSend({ ...dataSend, money: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "01", selectPay: 1, MEMO1: res.data?.MEMO1 })
            setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
            setDataCode({ codeQ: "", codeCCB: "" })
            form3.setFieldsValue({ codeQ: "", codeCCB: "" })
            form2.setFieldsValue({
              money: "",
              sale: "",
              saleFollow: "",
              PAYCODE: "01",
              selectPay: 1,
            });
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log("err", err)
          // setCheckAgain(true);
          // setCheckCt(true)
          // onChangecheckGuas("")
          // setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          // setDataTable([])
          // setDataTableDetail([])
          // setDataHDPAYMENT([])
          // setDataAROTHR([])
          // setDataPay([])
          // setDataCode({ codeQ: "", codeCCB: "" })
          clearData(0)
          msData(api, "top")
          setLoading(false)
        })
    } else if (isClickedTy === 2 || isClickedTy === 3 || isClickedTy === 4) {
      if (dataSend.textData !== "") {
        setLoading(true)
        try {
          const results = await loadDataFindPAYTYP(dataSend.textData, dataSend.textData2, isClickedTy);
          if (!results) {
            msData(api, "top")
            setFindPAYTYP([])
          } else {
            setFindPAYTYP(results)
          }
        } catch (error) {
          console.error("Error in loadData: ", error);
          msData(api, "top")
          setFindPAYTYP([])
        } finally {
          setCheckCt(true)
          onChangecheckGuas("")
          setDataTable([])
          setDataTableDetail([])
          setDataPay([])
          setSum({
            sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow,
            HDPAY: 0, amt: 0, nopay: 0, GRDCOD: "", AROTHR: 0, HD: 0, payGRADE: 0,
            flagCutTon: false,
          })
          setCheckAgain(true);
          setDataHDPAYMENT()
          setDataAROTHR()
          setDataSend({ ...dataSend, money: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "01", selectPay: 1, MEMO1: "" })
          setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" })
          setDataCode({ codeQ: "", codeCCB: "" })
          form3.setFieldsValue({ codeQ: "", codeCCB: "" })
          form2.setFieldsValue({
            money: "",
            sale: "",
            saleFollow: "",
            PAYCODE: "01",
            selectPay: 1,
          });
          setLoading(false);
        }
      }
    } else {
      msConnt(api, "top")
    }
  }
  const showConfirm = () => {
    // const randomNumber = getRandomInt(3)
    // console.log("randomNumber", randomNumber)
    confirm({
      title: (
        <Row>
          <Form form={form}>
            <Col span={24}>
              <Form.Item label="" style={{ margin: 0 }}>
                <b>{"คอมเฟิร์มทำรายการ"}</b>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="เลขที่สัญญา" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{dataSend?.CONTNO}</b>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="รับเงิน" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{currencyFormatOne(dataSend?.money)}</b>
                <span style={{ marginLeft: '10px' }}>บาท</span>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="ส่วนลด" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{currencyFormatOne(dataSend?.sale)}</b>
                <span style={{ marginLeft: '10px' }}>บาท</span>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="ส่วนลดค่าทวงถาม" style={{ margin: 0 }}>
                <b style={{ color: 'blue' }}>{currencyFormatOne(dataSend?.saleFollow)}</b>
                <span style={{ marginLeft: '10px' }}>บาท</span>
              </Form.Item>
            </Col>
          </Form>
        </Row>
      ),
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        setLoading(true);
        setFormKey(prevKey => prevKey + 1);
        setFormKey2(prevKey => prevKey + 1);
        setFormKey3(prevKey => prevKey + 1);
        // if (randomNumber > 0) {
        //   setTimeout(() => {
        insert();
        // }, randomNumber * 1000);
        // } else {
        //   insert();
        // }
      },
      onCancel() {
        setFormKey(prevKey => prevKey + 1);
        setFormKey2(prevKey => prevKey + 1);
        setFormKey3(prevKey => prevKey + 1);
        // form.resetFields()
        msCancel(api, "top");
      },
    });
  };
  const showConfirmClear = () => {
    confirm({
      title: 'คุณต้องการที่ล้างข้อมูลนี้ใช่หรือไม่ ?',
      content: 'กด OK เพื่อยืนยัน',
      onOk() {
        // setLoading(true)
        clearData(0)
      },
      onCancel() {
        msCancel(api, "top")
      },
    });
  }
  const clearData = async (value) => {
    setLoading(true);
    console.log("clearData", value)
    if (value > 0) {
      setIsClickedTy(value)
      form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: value })
    } else {
      setIsClickedTy(1)
      form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: 1 })
    }
    form2.setFieldsValue({
      selectPay: "ปกติ",
      money: "",
      sale: "",
      saleFollow: "",
      PAYCODE: "01",
    });
    form3.setFieldsValue({ codeQ: "", codeCCB: "" })
    setTimeout(() => {
      setCheckGuas("")
      setDataRec(null);
      setCheckContnoNew(false);
      setCheckAgain(true);
      setShowTable(false);
      setShowTableMulti(false);
      setIsClicked(1);
      // setIsClickedTy(1);
      setFindPAYTYP([])
      setCheckDataInsert(false);
      setCheckButton(1);
      setCheckButtonPay(true);
      setCheckCt(true);
      setDataCode({ codeQ: "", codeCCB: "" })
      setDataSend({ textData: "", textData2: "", CONTNO: "", money: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "01", selectPay: 1, MEMO1: "", });
      setDataAll(null);
      setDataHDPAYMENT([]);
      setDataAROTHR([]);
      setDataTable([]);
      setDataTableDetail([]);
      setDataPay([]);
      setCalData({ ALLPAYAMT: 0, ALLPAYFOLLOW: 0, ALLPAYINTEFF: 0, ALLPAYTON: 0, ALLCAL: 0, text: "" });
      setSum({ sumAmt: 0, sumFollow: 0, sumAll: 0, sumMoney: dataSend.money + dataSend.sale + dataSend.saleFollow, HDPAY: 0, amt: 0, nopay: 0, GRDCOD: "", AROTHR: 0, HD: 0, payGRADE: 0, flagTMPTELDEBT: null, flagCutTon: false, });
      setLoading(false);
    }, 1000);
  }
  const insert = async () => {
    let mix = {}
    if (checkAgain === true) {
      // if (dataAll && dataAll?.result && dataAll?.detailPay && (dataSend.money + dataSend.sale + dataSend.saleFollow) >= sum?.amt) {
      if (dataAll && dataAll?.result) {
        if (checkDataInsert === true && checkContnoNew === true) {
          if (dataSend.PAYCODE && dataSend.PAYCODE !== "") {
            console.log("dataAll", dataAll)
            const tk = JSON.parse(token)
            const headers = {
              "Authorization": `Bearer ${tk}`,
              "Menu": JSON.stringify("26")
            }
            mix = { ...dataAll, PAYCODE: dataSend.PAYCODE, fcmToken: currentToken, MEMO1: dataSend.MEMO1 }
            console.log("mix", mix)
            // await axios.post("http://localhost:8070/auth-pay/confirm-pay", mix, { headers: headers })
            await axios.post(confirmPay, mix, { headers: headers })
              .then(async (res) => {
                console.log("ok insert", res.data)
                if (res.status === 200) {
                  setTimeout(() => {
                    setCheckAgain(false)
                    if (res.data?.codeQ !== "" && res.data?.codeCCB !== "") {
                      form3.setFieldsValue({ codeQ: res.data?.codeQ, codeCCB: res.data?.codeCCB });
                      setDataCode({ codeQ: res.data?.codeQ, codeCCB: res.data?.codeCCB })
                    } else {
                      form3.setFieldsValue({ codeQ: res.data?.codeQ, codeCCB: "" });
                      setDataCode({ codeQ: res.data?.codeQ, codeCCB: "" })
                    }
                    setLoading(false)
                    msOK(api, "top")
                  }, 1000);
                } else {
                  msErrorInst(api, "top")
                  setLoading(false)
                }
              })
              .catch((err) => {
                console.log("err", err)
                msError(api, "top")
                setLoading(false)
              })
          } else {
            msPAYCODE(api, "top")
            setLoading(false)
          }
        } else {
          msCal(api, "top")
          setLoading(false)
        }
      } else {
        msError(api, "top")
        setLoading(false)
      }
    } else {
      msErrorInsert(api, "top")
      setLoading(false)
    }
  }

  const exportToExcel = async () => {
    if (dataTable?.length > 0) {
      var allData = []
      const workbook = new ExcelJS.Workbook();
      if (isClicked === 1) {
        allData = await DataExcelPay(allData, dataTable);
        // สร้างชีทและเพิ่มข้อมูลลงในแต่ละชีท
        const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
          const worksheet = workbook.addWorksheet(sheetName);
          // กำหนดความกว้างของแต่ละคอลัมน์
          worksheet.columns = mainDataExcel
          worksheet.addRow(headers_thai);
          dataSource.forEach((row) => {
            const rowData = headers_colums_name.map((column) => row[column]);
            worksheet.addRow(rowData);
          });
        };
        // หัวข้อใน Excel
        const headers_thai = headersThai
        const headers_colums_name = headersColumsName
        // // นำหัวข้อลงในแถวแรกของ Excel
        // // เพิ่มข้อมูลลงในแต่ละชีท 
        addSheet(`รายงานรับเงินสัญญา ${dataSend.CONTNO}`, allData, headers_colums_name, headers_thai);
        // addSheet(`รายงานรับเงินสัญญา ${"3-0001310"}`, allData, headers_colums_name, headers_thai);
        // console.log("addSheet")
        // บันทึกไฟล์ Excel
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          // สร้างลิงก์สำหรับดาวน์โหลดไฟล์ Excel
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `dataPay ${dataSend.CONTNO}.xlsx`);
          // link.setAttribute('download', `dataPay ${"3-0001310"}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      } else if (isClicked === 2) {
        allData = await DataExcelPay(allData, dataTableDetail);
        const addSheet = (sheetName, dataSource, headers_colums_name, headers_thai) => {
          const worksheet = workbook.addWorksheet(sheetName);
          worksheet.columns = mainDataExcelDetail
          worksheet.addRow(headers_thai);
          dataSource.forEach((row) => {
            const rowData = headers_colums_name.map((column) => row[column]);
            worksheet.addRow(rowData);
          });
        };
        const headers_thai = headersThaiDetail
        const headers_colums_name = headersColumsNameDetail
        addSheet(`รายงานรับเงินสัญญา ${dataSend.CONTNO}`, allData, headers_colums_name, headers_thai);
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `dataPayDetail ${dataSend.CONTNO}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      } else {
        console.log("รับฝาก")
      }
    } else {
      console.log("ไม่มีข้อมูล")
    }
  }

  const printpay = async () => {
    if (dataSend.CONTNO !== "" && dataCode.codeQ !== "") {
      let printData = {}
      let result = {}
      if (dataCode.codeQ !== "" && dataCode.codeCCB !== "" && dataCode.codeQ !== dataCode.codeCCB) {
        console.log("บิลปกติ+ปิด")
        setLoading(true)
        printData = [{ "CONTNO": dataSend.CONTNO, "TMBILL": dataCode.codeQ }, { "CONTNO": dataSend.CONTNO, "TMBILL": dataCode.codeCCB }]
        try {
          result = await loadPrintDataPaymentMulti(printData);
          setDataRec(result)
        } catch (error) {
          console.error("Error in handleInsert: ", error);
        } finally {

        }
        if (result === null) {
          console.log("เกิดข้อผิดพลาด");
          msPrint(api, "top")
          setDataRec(null)
          setLoading(false);
        } else {
          setShowTableMulti(true);
          setTimeout(async () => {
            setLoading(false);
            generatePDF2();
          }, 1000);
        }
      } else {
        console.log("บิลปกติ")
        setLoading(true)
        printData = { "CONTNO": dataSend.CONTNO, "TMBILL": dataCode.codeQ }
        try {
          result = await loadPrintDataPayment(printData);
          setDataRec(result)
        } catch (error) {
          console.error("Error in handleInsert: ", error);
        } finally {

        }
        if (result === null) {
          console.log("เกิดข้อผิดพลาด");
          msPrint(api, "top")
          setDataRec(null)
          setLoading(false);
        } else {
          setShowTable(true);
          setTimeout(async () => {
            setLoading(false);
            generatePDF();
          }, 1000);
        }
      }
    } else {
      msData(api, "top")
    }
  }

  return (
    <Card style={{ height: '100%' }}>
      <Row style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Spin spinning={loading} size='large' tip="Loading...">
            <Col span={24}>
              <div className='text-center'>
                <h2>รับเงิน</h2>
              </div>
              <div className='text-right'>
                <Space>
                  <Button onClick={printpay} type="primary" icon={<PrinterOutlined style={{ fontSize: '16px' }} />}
                    style={{ backgroundColor: '#5ceb97', borderColor: '#5ceb97' }}
                  >
                    พิมพ์ใบเสร็จ</Button>
                  {/* <Button onClick={exportToExcel}>Export to Excel</Button> */}
                  <Button disabled={!dataAll} type='primary' onClick={showConfirm} >บันทึกข้อมูล</Button>
                </Space>
              </div>
            </Col>
            {/* <Col span={24} style={{ textAlign: 'right' }}>
              </Col> */}
            <Divider />
            <Row gutter={32} justify={'center'}>
              <Col span={8}>
                <Card title="รายละเอียดสัญญา" bordered={false}>
                  <Form
                    form={form}
                    key={formKey}
                    onFinish={handleSubmit}
                  >
                    {/* <Space>
                      <Form.Item label="ค้นหาจาก" style={{ height: '30px', width: '100%' }}>
                        <Button style={{ marginBottom: '10px', background: isClickedTy === 1 ? '#DAF7A6' : 'white' }}
                          onClick={() => clearData(1)} >เลขสัญญา</Button>
                      </Form.Item>
                      <Form.Item label="" style={{ height: '30px', width: '100%' }}>
                        <Button style={{ marginBottom: '10px', background: isClickedTy === 2 ? '#DAF7A6' : 'white' }}
                          onClick={() => clearData(2)} >เลขบัตรหรือทะเบียนรถ</Button>
                      </Form.Item>
                    </Space> */}
                    {/* <Space> */}
                    <Form.Item label="วันที่ชำระ" name="date" style={{ height: '30px', width: '100%' }}>
                      <DatePicker format={'YYYY-MM-DD'}
                        disabled
                        defaultValue={currentDateTime}
                        style={{ height: '40px', width: '100%' }}
                        onChange={(e) => {
                          if (e) {
                            setDataSend({ ...dataSend, date: e.format("YYYY-MM-DDTHH:mm:ssZ") })
                          }
                        }} />
                    </Form.Item>
                    <Form.Item label="ค้นหาจาก" name="searchPay">
                      <Select
                        style={{ width: '100%', height: '40px' }}
                        defaultValue={isClickedTy}
                        onChange={clearData}
                        options={optionsSearchPay}
                      />
                    </Form.Item>
                    {/* <Form.Item label="" style={{ height: '30px', width: '100%' }}></Form.Item> */}
                    {/* </Space> */}
                    {/* <Space> */}
                    {
                      isClickedTy === 1 ?
                        <Form.Item label="เลขสัญญา" name="CONTNO" style={{ height: '30px', width: '100%' }}>
                          <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                        </Form.Item>
                        :
                        <>
                          {
                            isClickedTy === 3 ?
                              <>
                                <Form.Item label="ชื่อ-สกุล" name="textData" style={{ height: '30px', width: '100%' }}
                                  rules={[{ required: true, message: 'กรุณากรอกชื่อ-สกุล !' },]}
                                >
                                  <Input onChange={(e) => handleCONTNO(e, 31)}></Input>
                                </Form.Item>
                                <Form.Item label="" name="snam" style={{ height: '30px', width: '100%' }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{"***ไม่จำเป็นต้องใส่คำนำหน้าชื่อ***"}</b>
                                </Form.Item>
                                {/* <Form.Item label="นามสกุล" name="textData2" style={{ height: '30px', width: '100%' }}
                                  rules={[{ required: true, message: 'กรุณากรอกนามสกุล !' },]} >
                                  <Input onChange={(e) => handleCONTNO(e, 32)}></Input>
                                </Form.Item> */}
                              </>
                              :
                              <Form.Item label="ข้อความ" name="textData" style={{ height: '30px', width: '100%' }}>
                                <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                              </Form.Item>
                          }
                          {
                            findPAYTYP?.length > 0 ?
                              <Form.Item label="เลือกสัญญา" name="CONTNO">
                                <Select
                                  showSearch
                                  style={{ width: '100%', height: '40px' }}
                                  onChange={handleSelectCONTNO}
                                  optionFilterProp="children"
                                  placeholder="Search to Select"
                                  filterOption={(inputValue, option) =>
                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                  }
                                >
                                  {findPAYTYP?.map((e) => {
                                    return (
                                      <Select.Option key={e.keyId} value={e.CONTNO}>
                                        {e.CONTNO} (ทะเบียน {e.REGNO}) (ชื่อ {e.NAME1} {e.NAME2})
                                        {/* ({e.PAYDESC}) */}
                                      </Select.Option>
                                    )
                                  })}
                                </Select>
                              </Form.Item>
                              : null
                          }
                        </>
                    }
                    {/* </Space> */}
                    <Space>
                      <Form.Item label="" style={{ height: '30px', width: '100%', margin: '10px' }}>
                        <Button disabled={loading} type='primary' htmlType="submit" >ค้นหา</Button>
                        {/* <Button disabled={loading} type='primary' onClick={handleSubmit} >ค้นหา</Button> */}
                      </Form.Item>
                      <Form.Item label="" style={{ height: '30px', width: '100%', margin: '10px' }}>
                        <Button disabled={loading} onClick={showConfirmClear} >Clear</Button>
                      </Form.Item>
                    </Space>
                    <Divider />
                    {
                      calData?.ccb > 0 || dataPay?.ccb > 0 ?
                        <>
                          <Card style={{ borderColor: '#000000', margin: 0 }}>
                            <Row gutter={32} justify={"center"} align="middle">
                              <Col span={16}>
                                <Form.Item label="ยอดปิด" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '18px', color: 'red' }}>{currencyFormatOne(dataPay?.ccb ? dataPay?.ccb : calData?.ccb)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ต้นคงเหลือ" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataPay?.mixTon)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ดอกเบี้ย" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataPay?.mixDok)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ค่าทวงถาม" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataPay?.followCCB)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                          <Card style={{ borderColor: '#000000', marginTop: '5px' }}>
                            <Row gutter={32} justify={"center"} align="middle">
                              <Col span={16}>
                                <Form.Item label="ลูกหนี้อื่นๆ" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(sum?.AROTHR)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="รับฝาก" style={{ margin: 0 }}>
                                  <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(sum?.HD)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                          <Divider />
                          <Row gutter={32} justify={"center"}>
                            <Col>
                              <Space>
                                <Form.Item label="เกรด" style={{ marginRight: 10 }}>
                                  <b style={{ fontSize: '18px', color: 'red' }}>{sum?.GRDCOD}</b>
                                </Form.Item>
                                <Form.Item label="ค่างวด" style={{ marginLeft: 10 }}>
                                  <b style={{ fontSize: '18px', color: 'red' }}>{currencyFormatOne(sum?.amt)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                              </Space>
                              {
                                sum?.payGRADE > 0 ?
                                  <>
                                    <Form.Item label="ชำระขั้นต่ำ" style={{ margin: 0 }}>
                                      <b style={{ fontSize: '18px', color: 'red' }}>{currencyFormatOne(sum?.payGRADE)}</b>
                                      <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                    </Form.Item>
                                  </> : null
                              }
                            </Col>
                          </Row>
                        </>
                        : dataPay?.ccb === 0 ?
                          <b><div style={{ margin: 0, fontSize: '20px' }}>** สัญญาน่าจะปิดแล้ว  **</div></b>
                          : null
                    }
                    <Divider />
                    <Row gutter={32} justify={"center"}>
                      <Col span={16}>
                        <Form.Item label="ค้างค่างวด" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', }}>{currencyFormatOne(sum?.sumAmt)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Form.Item label="ค้างกี่งวด" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', }}>{currencyFormat(sum?.nopay)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> งวด</span>
                        </Form.Item>
                        <Form.Item label="ค้างค่าทวงถาม" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', }}>{currencyFormatOne(sum?.sumFollow)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        <Form.Item label="รวมยอดค้าง" style={{ margin: 0 }}>
                          <b style={{ fontSize: '14px', }}>{currencyFormatOne(sum?.sumAll)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form >
                </Card>
              </Col>
              <Col span={8}>
                <Card title="รับเงิน" bordered={false}>
                  <Form
                    form={form2}
                    key={formKey2}
                    onFinish={callHandleSubmitPay}
                  >
                    <Checkbox
                      style={{ fontSize: '16px' }}
                      checked={checkGuas === "จ่ายเข้าตารางดิว"}
                      // isPacked={true}
                      onChange={() => onChangecheckGuas("จ่ายเข้าตารางดิว")}
                    >จ่ายเข้าตารางดิว
                    </Checkbox>
                    <Checkbox
                      style={{ fontSize: '16px' }}
                      checked={checkGuas === "จ่ายแบบไม่เข้าตารางดิว"}
                      // isPacked={true}
                      // disabled={!sum?.flagCutTon}
                      disabled
                      onChange={() => onChangecheckGuas("จ่ายแบบไม่เข้าตารางดิว")}
                    >จ่ายแบบไม่เข้าตารางดิว
                    </Checkbox>
                    <Divider />
                    {/* {
                      checkGuas !== "" && dataTable?.length > 0 ?
                        <> */}
                    <Form.Item label="เลือก" name="selectPay">
                      <Select
                        // disabled
                        disabled={checkGuas === "จ่ายแบบไม่เข้าตารางดิว"}
                        style={{ width: '100%', height: '40px' }}
                        defaultValue={dataSend.selectPay}
                        onChange={changeSelectPay}
                        options={optionsPayType}
                      />
                    </Form.Item>
                    <Form.Item label="ประเภท" name="PAYCODE">
                      <Select
                        showSearch
                        style={{ width: '100%', height: '40px' }}
                        onChange={changePAYCODE}
                        optionFilterProp="children"
                        placeholder="Search to Select"
                        filterOption={(inputValue, option) =>
                          option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                        }
                      >
                        {getPAYCODE?.map((e) => {
                          return (
                            <Select.Option key={e.keyId} value={e.PAYCODE}>
                              {e.PAYCODE} ({e.PAYDESC})
                              {/* ({e.PAYDESC}) */}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="จำนวนเงิน" name="money">
                      <InputNumber
                        addonAfter="บาท"
                        disabled={checkCt}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        size="large"
                        placeholder="กรอกจำนวนเงิน"
                        style={{ width: '100%', color: 'black' }}
                        onChange={(value) => funcMoney(value)}
                      />
                    </Form.Item>
                    <Form.Item label="ส่วนลด" name="sale">
                      <InputNumber
                        addonAfter="บาท"
                        disabled={checkCt || checkGuas === "จ่ายแบบไม่เข้าตารางดิว"}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        size="large"
                        placeholder="กรอกจำนวนเงิน"
                        style={{ width: '100%', color: 'black' }}
                        onChange={(value) => funcSale(value)}
                      />
                    </Form.Item>
                    <Form.Item label="ส่วนลดค่าทวงถาม" name="saleFollow">
                      <InputNumber
                        addonAfter="บาท"
                        disabled={checkCt || checkGuas === "จ่ายแบบไม่เข้าตารางดิว"}
                        // disabled
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        size="large"
                        placeholder="กรอกจำนวนเงิน"
                        style={{ width: '100%', color: 'black' }}
                        onChange={(value) => funcSaleFollow(value)}
                      />
                    </Form.Item>
                    <Form.Item label="">
                      {/* <Button disabled={loading || !checkAgain} type='primary' onClick={callHandleSubmitPay} >คำนวณ</Button> */}
                      <Button disabled={loading || !checkAgain || checkCt} type='primary' htmlType="submit" >คำนวณ</Button>
                    </Form.Item>
                    {/* </>
                        : null
                    } */}
                  </Form >
                </Card>
              </Col>
              <Col span={8}>
                <Card title="รายละเอียดที่คำนวณได้" bordered={false}>
                  <Form form={form3}
                    key={formKey3}
                  // onFinish={handleSubmitMemo}
                  >
                    <Col span={24}>
                      <Form.Item label="เลขที่บิล" name="codeQ" style={{ margin: 5 }}>
                        <Input
                          variant="borderless"
                          disabled
                          size="large"
                          value={dataCode.codeQ}
                          style={{ width: '100%', color: 'black' }}
                        />
                      </Form.Item>
                      {
                        dataCode?.codeCCB !== "" && dataCode?.codeQ !== dataCode?.codeCCB ?
                          <Form.Item label="เลขที่บิล" name="codeCCB" style={{ margin: 5 }}>
                            <Input
                              variant="borderless"
                              disabled
                              size="large"
                              value={dataCode.codeCCB}
                              style={{ width: '100%', color: 'black' }}
                            />
                          </Form.Item>
                          : null
                      }
                    </Col>
                    <Divider />
                    <Row gutter={32} justify={"center"}>
                      <Col span={16}>
                        <Form.Item label="เลขที่สัญญา" style={{ margin: 0 }}>
                          <b style={{ color: 'blue' }}>{dataSend?.CONTNO}</b>
                        </Form.Item>
                        <Form.Item label="รับสุทธิ" name="money" style={{ margin: 0 }}>
                          <b>{currencyFormatOne(dataSend?.money)}</b>
                          <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                        </Form.Item>
                        {
                          sum.HDPAY > 0 ?
                            <Form.Item label="ตัดเข้าเงินต้น" name="HDPAY" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(sum.HDPAY)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            : null
                        }
                      </Col>
                    </Row>
                    <Divider />
                    <Row gutter={32} justify={"center"}>
                      {
                        calData.text === "" ?
                          <Col span={16}>
                            {
                              sum?.sumMoney > 0 && sum?.sumMoney === dataPay?.ccb && dataAll !== null ?
                                <Form.Item label="ข้อความ" style={{ margin: 0 }}>
                                  <Tag color="geekblue">{"ปิด บช."}</Tag>
                                </Form.Item>
                                : null
                            }
                            <Form.Item label="ชำระค่างวด" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            <Form.Item label="ชำระดอกเบี้ย" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            <Form.Item label="ชำระเงินต้น" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            <Form.Item label="ชำระค่าทวงถาม" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                            {
                              calData?.Dok > 0 ?
                                <Form.Item label="ดอกเบี้ยรายวัน" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.Dok)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                : null
                            }
                            {
                              calData?.PaySm > 0 ?
                                <Form.Item label="ตัดเข้าเงินต้นคงเหลือ" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.PaySm)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                : null
                            }
                            {
                              dataSend?.sale > 0 ?
                                <Form.Item label="ส่วนลด" name="sale" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(dataSend?.sale)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                : null
                            }
                            {
                              dataSend?.saleFollow > 0 ?
                                <Form.Item label="ส่วนลดค่าทวงถาม" name="saleFollow" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(dataSend?.saleFollow)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                : null
                            }
                            <Form.Item label="รวมยอดชำระ" style={{ margin: 0 }}>
                              <b>{currencyFormatOne(calData.ALLCAL)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                            </Form.Item>
                          </Col>
                          : calData.text === "เงินที่จ่ายเข้ามาเกินเงินต้นคงเหลือ" || calData.text === "จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ" ?
                            <Col span={16}>
                              <Form.Item label="Note" style={{ margin: 0 }}>
                                <Tag color="geekblue">{calData.text}</Tag>
                              </Form.Item>
                              <Form.Item label="ยอดที่รับได้ไม่เกิน" style={{ margin: 0 }}>
                                <b>{currencyFormatOne(dataPay?.ccb)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                              </Form.Item>
                            </Col>
                            : calData.text === "ไม่ตัดเข้าตารางดิว" ?
                              <Col span={16}>
                                <Form.Item label="Note" style={{ margin: 0 }}>
                                  <Tag color="geekblue">{calData.text}</Tag>
                                </Form.Item>
                                <Form.Item label="ชำระค่างวด" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLPAYAMT)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ชำระดอกเบี้ย" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLPAYINTEFF)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ชำระเงินต้น" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLPAYTON)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                                <Form.Item label="ชำระค่าทวงถาม" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLPAYFOLLOW)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                  <span style={{ marginLeft: '10px' }}></span>
                                </Form.Item>
                                <Form.Item label="รวมยอดชำระ" style={{ margin: 0 }}>
                                  <b>{currencyFormatOne(calData.ALLCAL)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                </Form.Item>
                              </Col>
                              : <Col span={12}>
                                <Form.Item label="Note" style={{ margin: 0 }}>
                                  <Tag color="geekblue">{calData.text}</Tag>
                                </Form.Item>
                              </Col>
                      }
                      <Divider />
                      <Col span={32}>
                        <Form.Item label="" style={{ margin: 0 }}>
                          <TextArea
                            rows={12} // ขยายความสูงให้แสดงบรรทัดมากขึ้น
                            cols={60} // ขยายความกว้างให้กว้างขึ้น
                            type='text'
                            disabled={checkCt}
                            value={dataSend?.MEMO1 || ""}
                            onChange={(e) => handleMEMO1(e.target.value)}
                          />
                        </Form.Item>
                        <Button disabled={checkCt} type='primary' htmlType="submit" style={{ margin: 10 }} onClick={handleSubmitMemo} >บันทึกโน๊ต</Button>
                      </Col>
                    </Row>
                  </Form >
                </Card>
              </Col>
            </Row>
            <Divider />
            <Col span={24}>
              <div className='text-right'>
                <Space>
                  <Button onClick={() => buttonAmt(1)} style={{ background: isClicked === 1 ? '#00FF00' : 'white' }}>ตารางค่างวด</Button>
                  <Button disabled={checkButtonPay} onClick={() => buttonAmt(2)} style={{ background: isClicked === 2 ? '#00FF00' : 'white' }}>ตารางรายละเอียดการจ่าย</Button>
                  <Button onClick={() => buttonAmt(3)} style={{ background: isClicked === 3 ? '#00FF00' : 'white' }}>ตารางรับฝาก</Button>
                  <Button onClick={() => buttonAmt(4)} style={{ background: isClicked === 4 ? '#00FF00' : 'white' }}>ตารางลูกหนี้อื่นๆ</Button>
                </Space>
              </div>
            </Col>
            {
              checkButton === 1 ?
                <Table
                  rowKey={(record) => record.uid}
                  dataSource={dataTable}
                  columns={columns}
                  scroll={{
                    x: 1500,
                    y: 400,
                  }}
                  style={{ padding: 20 }}
                >
                </Table>
                : checkButton === 2 ?
                  <Table
                    rowKey={(record) => record.uid}
                    dataSource={dataTableDetail}
                    columns={columnsDetail}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    style={{ padding: 20 }}
                  >
                  </Table>
                  : checkButton === 3 ?
                    <Table
                      rowKey={(record) => record.uid}
                      dataSource={dataHDPAYMENT}
                      columns={columnsHDPAYMENT}
                      scroll={{
                        x: 1500,
                        y: 400,
                      }}
                      style={{ padding: 20 }}
                    >
                    </Table>
                    : checkButton === 4 ?
                      <Table
                        rowKey={(record) => record.uid}
                        dataSource={dataAROTHR}
                        columns={columnsAROTHR}
                        scroll={{
                          x: 1500,
                          y: 400,
                        }}
                        style={{ padding: 20 }}
                      >
                      </Table>
                      : <><Divider />*** ไม่พบข้อมูล ***<Divider /></>
            }
          </Spin>
        </Col>
      </Row >
      {contextHolder}
      {
        showTable &&
        <div className="print-only" ref={componentPDF} style={{ textAlign: 'center' }}>
          <Payment
            dataPrint={dataRec}
          />
        </div>
      }
      <div className="print-only" ref={componentPDF2} justify={"center"}>
        {
          showTableMulti && dataRec && dataRec.length > 0
            ? dataRec.map((item, index) => (
              <Payment dataPrint={item} />
              // console.log(item, index)
            ))
            : []}
      </div>
    </Card >
  )
}
